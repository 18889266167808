import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { authSelector } from "../../store/slices/AuthSlice";
import { toggleSelector } from "../../store/slices/ToggleSlice";
import AreaChart from "./AreaChart";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { RunChart } from "../../services/DashboardService";
import CollapsibleCard from "../CollapsibleCard";
import { removeChart } from "../../store/slices/DashboardSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Chart({
  children,
  chartId,
  chartType = "line",
  className,
}: {
  children?: React.ReactNode;
  chartId: number;
  chartType?: string;
  className?: string;
}) {
  const { user } = useAppSelector(authSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { database, darkMode } = useAppSelector(toggleSelector);
  const [db, setDb] = useState<Database>(user?.defaultDatabase as Database);
  const [chartData, setChartData] = useState<ChartProps>({});
  const [data, setData] = useState<ReportData>({} as ReportData);

  const dispatch = useAppDispatch();

  const loadData = () => {
    setIsLoading(true);
    if (chartId && chartId === 0) {
      console.log("Chart ID is 0");
      setError("Chart ID is 0");
      setIsLoading(false);
      return;
    }
    if (db && db.id === 0) {
      console.log("Database ID is 0");
      setError("Database ID is 0");
      setIsLoading(false);
      return;
    }
    if (chartId && db && db.id) {
      RunChart(chartId, db.id)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
            setIsLoading(false);
          } else {
            setError(response.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onCardClose = () => {
    dispatch(removeChart(chartId));
  };

  useEffect(() => {
    setDb(user?.defaultDatabase as Database);
  }, [user]);

  useEffect(() => {
    loadData();
  }, [chartId]);

  useEffect(() => {
    if (data.data) {
      setChartData(data?.data as ChartProps);
    }
  }, [data]);

  return (
    <CollapsibleCard
      title={data.title || "Loading..."}
      isLoading={isLoading}
      onReload={() => loadData()}
      className={className}
      onClose={onCardClose}
    >
      {error ? (
        <>
          <div className='text-center'>
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              size='3x'
              className='mb-2 text-warning'
            />
            <br />
            <p className='text-bold'>{error}</p>
          </div>
        </>
      ) : chartType === "area" ? (
        <AreaChart labels={chartData.labels} dataset={chartData.dataset} />
      ) : chartType === "line" ? (
        <LineChart labels={chartData.labels} dataset={chartData.dataset} />
      ) : chartType === "bar" ? (
        <BarChart labels={chartData.labels} dataset={chartData.dataset} />
      ) : chartType === "pie" ? (
        <PieChart labels={chartData.labels} dataset={chartData.dataset} />
      ) : (
        children
      )}
    </CollapsibleCard>
  );
}
