import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SearchForm() {
  return (
    <div className='form-inline'>
      <div className='input-group'>
        <input
          className='form-control form-control-sidebar'
          type='search'
          placeholder='Search'
          aria-label='Search'
        />
        <div className='input-group-append'>
          <button className='btn btn-sidebar'>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
    </div>
  );
}
