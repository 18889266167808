import { IconName, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

type InputProps = {
  name?: string;
  id?: string;
  placeholder?: string;
  label: string;
  icon?: IconName;
  value?: any;
  onValueChange?: any;
};

interface Row {
  key: string;
  type: string;
  value: string;
}

export default function KeyValueInputTable(input: InputProps) {
  const types = [
    "text",
    "number",
    "date",
    "list",
    "boolean",
    "+sectorlar",
    "+depolar",
    "+qruplar",
    "+bolgeler",
    "+personeller",
    "+merkezler",
  ];
  const [rows, setRows] = useState([] as Row[]);
  const [row, setRow] = useState({} as Row);
  const [ready, setReady] = useState(false);

  const addRow = () => {
    const row = {
      key: "",
      type: "text",
      value: "",
    };
    setRows([...rows, row]);
  };

  const removeRow = (index: number) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const updateRow = (index: number, key: string, value: string) => {
    setRows(
      rows.map((row, i) => (i === index ? { ...row, [key]: value } : row))
    );
  };

  const readyHandler = () => {
    if (!ready) {
      input.onValueChange(JSON.stringify(rows));
    }
    setReady(!ready);
  };

  useEffect(() => {
    if (input.value) {
      setRows(JSON.parse(input.value));
    }
  }, [input.value]);

  return (
    <div className='form-group ml-2'>
      <div className='row'>
        <div className='col-12 mb-1'>
          <label htmlFor={input.id}>{input.label}</label>
          <button
            className='btn btn-sm btn-success ml-2'
            onClick={addRow}
            disabled={ready}
          >
            Add
          </button>
          <button
            className={`btn btn-sm ${
              ready ? "btn-danger" : "btn-success"
            } ml-2`}
            onClick={readyHandler}
          >
            {!ready ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </button>
        </div>
      </div>
      <div className='input-group'>
        <div className='row'>
          <div className='col-12'>
            {rows.map((row, index) => (
              <div className='row mb-1' key={index}>
                <div className='col-4'>
                  {index === 0 && <label>Parameter</label>}
                  <br />
                  <input
                    className='form-control form-control-sm'
                    type='text'
                    value={row.key}
                    onChange={(e) => updateRow(index, "key", e.target.value)}
                    disabled={ready}
                  />
                </div>
                <div className='col-4'>
                  {index === 0 && <label>Type</label>}
                  <br />
                  <select
                    className='form-control form-control-sm'
                    value={row.type}
                    onChange={(e) => updateRow(index, "type", e.target.value)}
                    disabled={ready}
                  >
                    {types.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-4'>
                  {index === 0 && <label>-</label>}
                  <br />
                  <button
                    className='btn btn-sm btn-danger'
                    onClick={() => removeRow(index)}
                    disabled={ready}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
