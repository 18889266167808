import { AUTH_USER_URL, LOGIN_URL, LOGOUT_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function Signin(data: any) {
  return axios
    .post(LOGIN_URL, data)
    .then((response): ResponseModel<User> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function Signout() {
  return axios
    .get(LOGOUT_URL)
    .then((response): ResponseModel<User> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function VerifyToken(token: string) {
  return axios
    .get(LOGIN_URL + `verify/${token}`)
    .then((response): ResponseModel<any> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<any> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
