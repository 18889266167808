import { Capitalize } from "../../utils/extensions";
import Input from "../Inputs/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomParamInput from "./CustomParamInput";

export default function DynamicInputForParams({
  params: params,
  onChange: onChange,
}: {
  params: ReportParam[];
  onChange: any;
}) {
  const changeHandler = (param: ReportParam, value: any) => {
    onChange({ ...param, value: value });
  };

  return (
    <>
      {params &&
        params.length > 0 &&
        params.map((param: ReportParam) =>
          param.type === "text" ? (
            <div className='col-6' key={param.key}>
              <Input
                id={param.key}
                label={Capitalize(param.key)}
                placeholder={"Enter " + param.key}
                name={param.key}
                icon='font'
                type='text'
                value={param.value}
                onChange={(e: any) => changeHandler(param, e.target.value)}
              />
            </div>
          ) : param.type === "number" ? (
            <div className='col-6' key={param.key}>
              <Input
                id={param.key}
                label={Capitalize(param.key)}
                placeholder={"Enter " + param.key}
                name={param.key}
                icon='hashtag'
                type='number'
                value={param.value}
                onChange={(e: any) => changeHandler(param, e.target.value)}
              />
            </div>
          ) : param.type === "date" ? (
            <div className='col-6' key={param.key}>
              <Input
                id={param.key}
                label={Capitalize(param.key)}
                placeholder={"Enter " + param.key}
                name={param.key}
                icon='calendar-alt'
                type='date'
                value={param.value}
                onChange={(e: any) => changeHandler(param, e.target.value)}
              />
            </div>
          ) : param.type === "list" ? (
            <div className='col-6' key={param.key}>
              <Input
                id={param.key}
                label={Capitalize(param.key) + " (comma seperated)"}
                placeholder={"Enter " + param.key}
                name={param.key}
                icon='list'
                type='text'
                value={param.value}
                onChange={(e: any) => changeHandler(param, e.target.value)}
              />
            </div>
          ) : param.type === "boolean" ? (
            <div className='col-6' key={param.key}>
              <div className='form-group'>
                <label htmlFor={param.key}>{Capitalize(param.key)}</label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      <FontAwesomeIcon icon={["fas", "check-square"]} />
                    </span>
                  </div>
                  <select
                    className='form-control'
                    value={param.value}
                    onChange={(e: any) => changeHandler(param, e.target.value)}
                  >
                    <option value='true'>True (1)</option>
                    <option value='false'>False (0)</option>
                  </select>
                </div>
              </div>
            </div>
          ) : param.type.startsWith("+") ? (
            <CustomParamInput param={param} onChange={changeHandler} />
          ) : (
            <div className='col-6' key={param.key}>
              <Input
                id={param.key}
                label={Capitalize(param.key)}
                placeholder={"Enter " + param.key}
                name={param.key}
                icon='font'
                type='text'
                value={param.value}
                onChange={(e: any) => changeHandler(param, e.target.value)}
              />
            </div>
          )
        )}
    </>
  );
}
