import { DATABASES_URL, DATABASE_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetDatabases(qparams?: any) {
  return axios
    .get(DATABASES_URL, { params: { ...qparams } })
    .then((response): ResponseModel<Database[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Database[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetDatabase(id: number) {
  return axios
    .get(DATABASE_URL + `${id}`)
    .then((response): ResponseModel<Database> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Database> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateDatabase(id: any, database: FormData) {
  return axios
    .put(DATABASE_URL + `${id}`, database)
    .then((response): ResponseModel<Database> => {
      let database = response.data as Database;
      return {
        data: response.data,
        status: response.status,
        message: `${database.name} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<Database> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertDatabase(database: FormData) {
  return axios
    .post(DATABASE_URL, database)
    .then((response): ResponseModel<Database> => {
      let database = response.data as Database;
      return {
        data: response.data,
        status: response.status,
        message: `${database.name} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<Database> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteDatabase(id: number) {
  return axios
    .delete(DATABASE_URL + `${id}`)
    .then((response): ResponseModel<Database> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<Database> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
