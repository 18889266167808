import { useState } from "react";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleLang, toggleLangChanger } from "../../store/slices/ToggleSlice";

export default function LanguageSelector() {
  const { t, i18n } = useTranslation(["translation", "content"]);
  const dispatch = useDispatch<any>();
  const { langChanger, langList, lang } = useSelector(
    (state: any) => state.toggle
  );

  const changeHandler = (lang: any) => {
    localStorage.setItem("lang", lang);
    dispatch(toggleLangChanger(langChanger));
    dispatch(toggleLang(lang));
    i18n.changeLanguage(lang);
  };
  return (
    <div className={"nav-item dropdown " + (langChanger ? "show" : "")}>
      <span
        onClick={() => {
          dispatch(toggleLangChanger(langChanger));
        }}
        className='nav-link'
      >
        <Flag code={lang == "EN" ? "GB" : lang} width='20' className='mr-1' />
        {lang}
      </span>

      <div
        className={
          "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
          (langChanger ? "show" : "")
        }
      >
        {langList.map((lng: string) => {
          return (
            <span
              key={lng}
              className='dropdown-item'
              onClick={() => {
                changeHandler(lng);
              }}
            >
              <Flag
                code={lng == "EN" ? "GB" : lng}
                width='24'
                className='mr-2'
              />
              <span>{lng}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
