import React, { useState } from "react";
import DynamicInputForParams from "./DynamicInputForParams";
import toast from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function ParamAccordion({
  params: params,
  onChange: onChange,
  onClick: onClick,
}: {
  params: ReportParam[];
  onChange: any;
  onClick: any;
}) {
  const [collapsed, setCollapsed] = useState(true);

  const validateParams = (params: ReportParam[]) => {
    const valid = params.every((param) => {
      if (!param.value) {
        toast.error(`Please fill ${param.key.toUpperCase()} parameter`);
        return false;
      } else {
        return true;
      }
    });
    if (valid) {
      onClick(params);
    }
  };

  return (
    <div className='no-select' id='accordion'>
      <div className='card card-primary'>
        <div
          className='card-header d-flex'
          onClick={() => setCollapsed(!collapsed)}
        >
          <h4 className='card-title w-100'>
            <a className='d-block w-100 collapsed'>Parameters</a>
          </h4>
          <FontAwesomeIcon
            icon={collapsed ? faAngleDown : faAngleUp}
            className='mt-1'
          />
        </div>
        <AnimatePresence initial={false}>
          {collapsed && (
            <motion.div
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className='card-body no-select'>
                <div className='row'>
                  <DynamicInputForParams params={params} onChange={onChange} />
                </div>
                <div className='row justify-content-end mr-1 mb-1'>
                  <button
                    className='btn btn-sm btn-success'
                    onClick={() => {
                      validateParams(params);
                    }}
                  >
                    Get report
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
