import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function MachedParams({ code }: { code: string }) {
  const [matches, setMatches] = useState<any>([]);

  const getParamList = (code: string) => {
    const inputText = code;
    const pattern = /(:\w+)/g;
    const foundMatches = inputText.match(pattern) || [];
    const uniqueMatches = Array.from(new Set(foundMatches));
    setMatches(uniqueMatches);
  };

  useEffect(() => {
    getParamList(code);
  }, [code]);
  return (
    <div className='form-group'>
      <label>Matched params: </label>
      <div className='form-control'>
        {matches.map((match: any, index: number) => (
          <div
            style={{ cursor: "pointer" }}
            className='badge badge-primary ml-1 p-1'
            key={index}
            onClick={() => {
              navigator.clipboard.writeText(match.replace(":", ""));
            }}
          >
            {match.replace(":", "")}
            <span
              style={{ cursor: "pointer", borderLeft: "1px solid" }}
              className='ml-1'
            ></span>
            <FontAwesomeIcon
              icon={["fas", "add"]}
              className='ml-1'
              style={{ cursor: "pointer" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
