import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import {
  dashboardSelector,
  setAvailableMaps,
} from "../../store/slices/DashboardSlice";
import { GetMaps } from "../../services/DashboardService";
import GoogleMap from "../GoogleMap";

export default function MapContainer() {
  const { maps } = useAppSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const [grouppedMaps, setGrouppedMaps] = useState<any[]>([]);

  const loadMaps = async () => {
    try {
      await GetMaps().then((res) => {
        if (res.status === 200) {
          dispatch(setAvailableMaps(res.data));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const initLayout = () => {
    if (maps && maps.length > 0) {
      const grp = [];
      for (let i = 0; i < maps.length; i += 2) {
        grp.push(maps.slice(i, i + 2));
      }
      setGrouppedMaps(grp);
    }
  };

  useEffect(() => {
    initLayout();
  }, [maps]);

  useEffect(() => {
    loadMaps();
  }, []);

  return (
    <>
      {grouppedMaps.map((group, index) => (
        <div className='row' key={index}>
          {group.map((map: DashMap, idx: number) => (
            <GoogleMap
              scrollwheel={false}
              key={idx}
              id={map.id}
              title={map.title}
              className={
                group.length === 1
                  ? "col-12 col-sm-12 col-md-12 col-lg-12"
                  : "col-12 col-sm-12 col-md-6 col-lg-6"
              }
            />
          ))}
        </div>
      ))}
    </>
  );
}
