import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({ labels, dataset }: ChartProps) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    maintainAspectRatio: true,
  };

  const dataStr = {
    labels: labels,
    datasets: dataset ?? [],
  };
  return <Pie options={options} data={dataStr} />;
}
