export const BASE_URL = "http://45.141.151.62:8181/api/";
export const LOGIN_URL = "auth/";
export const LOGOUT_URL = "auth/logout/";
export const AUTH_USER_URL = "auth/getuser";
export const DATABASES_URL = "/database/databases/";
export const DATABASE_URL = "/database/database/";
export const REPORTS_URL = "/report/reports/";
export const REPORT_URL = "/report/report/";
export const ROLES_URL = "/role/roles/";
export const ROLE_URL = "/role/role/";
export const USERS_URL = "/user/users/";
export const USER_URL = "/user/user/";
export const TASKS_URL = "/task/tasks/";
export const TASK_URL = "/task/task/";
export const DASHBOARD_URL = "/dashboard/";
export const CHARTS_URL = "/dashboard/charts";
export const INFOCARDS_URL = "/dashboard/infocards";
export const MAPS_URL = "/dashboard/maps";
export const MIKRO_URL = "/mikro/";