import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CollapsibleCard from "../../components/CollapsibleCard";
import DataTable from "../../components/CustomDataTable";
import { GetReport, RunReport } from "../../services/ReportService";
import toast from "react-hot-toast";
import { useAppSelector } from "../../utils/Hooks";
import { toggleSelector } from "../../store/slices/ToggleSlice";
import { authSelector } from "../../store/slices/AuthSlice";
import ParamAccordion from "../../components/ReportParams/ParamAccordion";
import GoogleMap from "../../components/GoogleMap";

export default function Report() {
  const { id } = useParams();
  const { user } = useAppSelector(authSelector);
  const { database } = useAppSelector(toggleSelector);

  const [report, setReport] = React.useState<ReportData>({} as ReportData);
  const [params, setParams] = React.useState<ReportParam[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [db, setDb] = useState<Database>(
    database ? database : (user?.defaultDatabase as Database)
  );
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const controller = new AbortController();

  const loadData = async () => {
    const signal = controller.signal;

    setIsLoading(true);
    if (db.id === 0) {
      toast.error("Please select a database");
      setIsLoading(false);
      return;
    }
    if (id && db.id) {
      RunReport(+id, db.id, params, signal)
        .then((response) => {
          if (response.status === 200) {
            setReport(response.data);
            setIsLoading(false);
          } else {
            if (signal.aborted) {
              toast.error("Request canceled by user");
            } else {
              toast.error(response.message);
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const getReportParams = async () => {
    setIsLoading(true);
    if (id) {
      GetReport(+id)
        .then((response) => {
          if (response.status === 200) {
            var params = JSON.parse(response.data.sqlParams ?? "[]");
            setParams(params);
            setTitle(response.data.title);
            setDescription(response.data.description);
            setIsLoading(false);
            if (params.length === 0) {
              console.log("load");
              loadData();
            }
          } else {
            toast.error(response.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const changeHandler = (param: ReportParam) => {
    const curParamIndex = params.findIndex((p) => p.key === param.key);
    setParams((prevItems) => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[curParamIndex] = param; // Replace the object at the specified index
      return newItems; // Return the new array
    });
  };

  useEffect(() => {
    setDb(database ? database : (user?.defaultDatabase as Database));
  }, [database]);

  useEffect(() => {
    setReport({} as ReportData);
    setParams([]);
    getReportParams();
  }, [id]);

  useEffect(() => {
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, []);

  return (
    <CollapsibleCard title={title} isLoading={isLoading} onReload={loadData}>
      <div className='px-2'>
        {params && params.length > 0 && (
          <ParamAccordion
            onChange={changeHandler}
            params={params}
            onClick={loadData}
          />
        )}
        {report.hasMap && (
          <>
            <GoogleMap
              className='my-2'
              id={report.id}
              title='Map'
              params={params}
            />
          </>
        )}
        <div>
          {report.data && report.data.length > 0 && (
            <DataTable data={report?.data ?? []} />
          )}
        </div>
        <div className='callout callout-secondary mx-2'>
          <p>{description}</p>
        </div>
      </div>
    </CollapsibleCard>
  );
}
