import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Signin } from "../../services/AuthService";

interface InitState {
  user: User | null;
}

const initialState: InitState = {
  user: null,
};

//methods
export const login = createAsyncThunk("login", async (user: FormData) => {
  try {
    return await Signin(user);
  } catch (ex: any) {
    return ex.response.data;
  }
});

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { logout, setUser } = authSlice.actions;
export const authSelector = (state: RootState) => state.authReducer;
export default authSlice.reducer;
