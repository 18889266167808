import React, { useEffect } from "react";
import CollapsibleCard from "../../components/CollapsibleCard";
import toast from "react-hot-toast";
import Input from "../../components/Inputs/Input";
import RichEditor from "../../components/Inputs/RichEditor/RichEditor";
import Select from "../../components/Inputs/Select";
import { useNavigate, useParams } from "react-router-dom";
import MemberSelect from "../../components/Inputs/MemberSelect";
import { GetTask, UpdateTask } from "../../services/TaskService";
import moment from "moment";
import { GetUsers } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TaskEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const formData: Task = {
    title: "",
    description: "",
    priority: {} as TaskPriority,
    status: {} as TaskStatus,
    user: {} as User,
    dueDate: new Date(),
    members: [],
  };
  const memberData: User[] = [];
  const [task, setTask] = React.useState<Task>(formData);
  const [members, setMembers] = React.useState<User[]>(memberData);
  const [loading, setLoading] = React.useState(false);

  const updateHandler = (data: Task) => {
    setLoading(true);
    UpdateTask(id, data).then((response) => {
      if (response.status === 200) {
        toast.success(`${data.title} updated successfully.`);
        navigate("/tasks");
      } else {
        toast.error(`${response.message}`);
      }
    });
    setLoading(false);
  };

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/tasks")}
      >
        Cancel
      </button>
      <button
        className='btn btn-outline-success'
        onClick={() => updateHandler(task)}
      >
        {loading ? <FontAwesomeIcon icon='spinner' spin /> : "Save"}
      </button>
    </div>
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      GetTask(+id).then((response) => {
        if (response.status === 200) {
          setTask(response.data);
          setLoading(false);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    GetUsers().then((response) => {
      setMembers(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <CollapsibleCard
      title={"Edit :" + task.title}
      footer={_footerContent}
      isLoading={loading}
    >
      <div className='m-2'>
        <Input
          id='title'
          label='Title'
          placeholder='Enter Title'
          name='title'
          icon='pencil-alt'
          type='text'
          value={task.title}
          onChange={(e: any) => setTask({ ...task, title: e.target.value })}
        />
      </div>
      <div className='m-2'>
        <RichEditor
          id='description'
          label='Description'
          name='description'
          placeholder='Enter Description'
          icon='spell-check'
          value={task.description}
          onChange={(e: any) =>
            setTask({ ...task, description: e.target.value })
          }
        />
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6'>
                <Select
                  label='Status'
                  id='status'
                  name='status'
                  options={[
                    { value: "open", label: "Open" },
                    { value: "closed", label: "Closed" },
                  ]}
                  icon='check'
                  value={task.status}
                  onChange={(value: any) => setTask({ ...task, status: value })}
                />
              </div>
              <div className='col-6'>
                <Select
                  label='Priority'
                  id='priority'
                  name='priority'
                  options={[
                    { value: "low", label: "Low" },
                    { value: "normal", label: "Normal" },
                    { value: "high", label: "High" },
                  ]}
                  icon='signal'
                  value={task.priority}
                  onChange={(value: any) =>
                    setTask({ ...task, priority: value })
                  }
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <Input
              label='Due Date'
              id='dueDate'
              name='dueDate'
              icon='calendar'
              placeholder='Enter Due Date'
              type='date'
              value={moment(task.dueDate).format("YYYY-MM-DD")}
              onChange={(e: any) =>
                setTask({ ...task, dueDate: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              label='Assignee'
              id='assignee'
              name='assignee'
              icon='user'
              value={task.user.displayName ?? task.user.username}
              onChange={(e: any) => {}}
              type='text'
              disabled
            />
          </div>
          <div className='col-6'>
            <MemberSelect
              label='Assigned To'
              id='members'
              name='members'
              members={members}
              icon='users'
              isMulti={true}
              value={task.members}
              onChange={(value: any) => setTask({ ...task, members: value })}
            />
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
