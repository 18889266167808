import React, { useEffect } from "react";
import CollapsibleCard from "../../components/CollapsibleCard";
import toast from "react-hot-toast";
import Input from "../../components/Inputs/Input";
import RichEditor from "../../components/Inputs/RichEditor/RichEditor";
import Select from "../../components/Inputs/Select";
import { useNavigate } from "react-router-dom";
import { GetUsers } from "../../services/UserService";
import { InsertTask } from "../../services/TaskService";
import MemberSelect from "../../components/Inputs/MemberSelect";
import { useAppSelector } from "../../utils/Hooks";
import { authSelector } from "../../store/slices/AuthSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TaskAdd() {
  const { user } = useAppSelector(authSelector);
  const navigate = useNavigate();
  const formData: Task = {
    title: "",
    description: "",
    priority: { value: "normal", label: "Normal" } as TaskPriority,
    status: { value: "open", label: "Open" } as TaskStatus,
    user: user as User,
    dueDate: new Date(),
    members: [],
  };
  const [task, setTask] = React.useState<Task>(formData);
  const [users, setUsers] = React.useState<User[]>([]);
  const [currentUser, setCurrentUser] = React.useState<User>({} as User);
  const [loading, setLoading] = React.useState(false);

  const addHandler = (data: Task) => {
    setLoading(true);
    InsertTask(data).then((response) => {
      if (response.status === 200) {
        toast.success(`${data.title} added successfully.`);
        navigate("/tasks");
      } else {
        toast.error(`${response.message}`);
      }
    });
    setLoading(false);
  };

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/tasks")}
      >
        Cancel
      </button>
      <button
        className='btn btn-outline-success'
        onClick={() => addHandler(task)}
      >
        {loading ? <FontAwesomeIcon icon='spinner' spin /> : "Save"}
      </button>
    </div>
  );

  useEffect(() => {
    setLoading(true);
    GetUsers().then((response) => {
      setUsers(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <CollapsibleCard
      title='Add New Task'
      footer={_footerContent}
      isLoading={loading}
    >
      <div className='m-2'>
        <Input
          id='title'
          label='Title'
          placeholder='Enter Title'
          name='title'
          icon='pencil-alt'
          type='text'
          value={task.title}
          onChange={(e: any) => setTask({ ...task, title: e.target.value })}
        />
      </div>
      <div className='m-2'>
        <RichEditor
          id='description'
          label='Description'
          name='description'
          placeholder='Enter Description'
          icon='spell-check'
          value={task.description}
          onChange={(e: any) =>
            setTask({ ...task, description: e.target.value })
          }
        />
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6'>
                <Select
                  label='Status'
                  id='status'
                  name='status'
                  options={[
                    { value: "open", label: "Open" },
                    { value: "closed", label: "Closed" },
                  ]}
                  icon='check'
                  value={task.status}
                  onChange={(status: TaskStatus) =>
                    setTask({ ...task, status: status })
                  }
                />
              </div>
              <div className='col-6'>
                <Select
                  label='Priority'
                  id='priority'
                  name='priority'
                  options={[
                    { value: "low", label: "Low" },
                    { value: "normal", label: "Normal" },
                    { value: "high", label: "High" },
                  ]}
                  icon='signal'
                  defaultValue={task.priority}
                  onChange={(priority: TaskPriority) =>
                    setTask({ ...task, priority: priority })
                  }
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <Input
              label='Due Date'
              id='dueDate'
              name='dueDate'
              icon='calendar'
              placeholder='Enter Due Date'
              type='date'
              value={task.dueDate}
              onChange={(e: any) =>
                setTask({ ...task, dueDate: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              label='Assignee'
              id='assignee'
              name='assignee'
              icon='user'
              value={task.user.displayName}
              type='text'
              disabled
            />
          </div>
          <div className='col-6'>
            {users && (
              <MemberSelect
                label='Assigned To'
                id='members'
                name='members'
                members={users}
                icon='users'
                isMulti={true}
                onChange={(value: any) => setTask({ ...task, members: value })}
                value={task.members}
              />
            )}
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
