import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitState {
  darkMode: boolean;
  alertState: boolean;
  alertList: any[];
  modalState: boolean;
  sideBarOpen: boolean;
  langChanger: boolean;
  langList: string[];
  lang: string;
  databaseChanger: boolean;
  database: Database | null;
}

const initialState: InitState = {
  darkMode: JSON.parse(localStorage.getItem("darkMode") ?? "false") || false,
  alertState: true,
  alertList: [],
  modalState: false,
  sideBarOpen: false,
  langChanger: false,
  langList: ["AZ", "TR", "EN", "RU"],
  lang: localStorage.getItem("lang") || "AZ",
  databaseChanger: false,
  database: null,
};

const hideAlert = (alerts: any, id: number) => {
  let filter = [...alerts].filter((alert) => alert.id !== id);
  return filter;
};

const toggle = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    toggleDarkMode: (state, action) => {
      state.darkMode = !action.payload;
      localStorage.setItem("darkMode", (!action.payload).toString());
    },
    toggleModal: (state, action) => {
      state.modalState = action.payload;
    },
    toggleAlert: (state, action) => {
      state.alertList = hideAlert(state.alertList, action.payload);
    },
    showAlert: (state, action) => {
      state.alertList = [...state.alertList, action.payload];
    },
    toggleSidebar: (state) => {
      state.sideBarOpen = !state.sideBarOpen;
    },
    toggleLangChanger: (state) => {
      state.langChanger = !state.langChanger;
    },
    toggleLang: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem("lang", action.payload);
    },
    toggleDatabaseChanger: (state, action) => {
      state.databaseChanger = !state.databaseChanger;
    },
    toggleDatabase: (state, action) => {
      state.database = action.payload;
    },
  },
});

export const {
  toggleDarkMode,
  toggleModal,
  toggleAlert,
  showAlert,
  toggleSidebar,
  toggleLangChanger,
  toggleLang,
  toggleDatabaseChanger,
  toggleDatabase,
} = toggle.actions;
export const toggleSelector = (state: RootState) => state.toggle;
export default toggle.reducer;
