import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import ContentHeader from "./components/ContentHeader";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import { useSelector } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { Toaster } from "react-hot-toast";

library.add(fab);
library.add(far);
library.add(fas);

function App() {
  const { sideBarOpen, darkMode } = useSelector((state: any) => state.toggle);
  return (
    <div
      className={
        (darkMode ? " dark-mode " : " ") +
        " sidebar-mini  " +
        (sideBarOpen ? " sidebar-open " : " sidebar-close sidebar-collapse")
      }
    >
      <Toaster/>
      <div className='wrapper'>
        <Navbar />
        <Sidebar />
        <div className='content-wrapper'>
          <ContentHeader />
          <section className='content'>
            <Outlet />
          </section>
        </div>
        <Footer />
      </div>
      <Tooltip id='generalTooltip' style={{ padding: "5px" }}/>
    </div>
  );
}

export default App;
