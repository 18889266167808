import { TASKS_URL, TASK_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetTasks(qparams?: any) {
  return axios
    .get(TASKS_URL, { params: { ...qparams } })
    .then((response): ResponseModel<Task[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Task[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetTask(id: number) {
  return axios
    .get(TASK_URL + `${id}`)
    .then((response): ResponseModel<Task> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Task> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateTask(id: any, task: Task) {
  return axios
    .put(TASK_URL + `${id}`, task)
    .then((response): ResponseModel<Task> => {
      let task = response.data as Task;
      return {
        data: response.data,
        status: response.status,
        message: `${task.title} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<Task> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertTask(task: Task) {
  return axios
    .post(TASK_URL, task)
    .then((response): ResponseModel<Task> => {
      let task = response.data as Task;
      return {
        data: response.data,
        status: response.status,
        message: `${task.title} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<Task> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteTask(id: number) {
  return axios
    .delete(TASK_URL + `${id}`)
    .then((response): ResponseModel<Task> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<Task> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
