import React, { useCallback, useEffect, useState } from "react";
import {
  APIProvider,
  InfoWindow,
  Map,
  AdvancedMarker,
  Marker,
} from "@vis.gl/react-google-maps";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { toggleSelector } from "../../store/slices/ToggleSlice";
import CollapsibleCard from "../CollapsibleCard";
import {
  dashboardSelector,
  setShowMap,
} from "../../store/slices/DashboardSlice";
import { authSelector } from "../../store/slices/AuthSlice";
import { RunMap } from "../../services/DashboardService";

export default function GoogleMap({
  id,
  title,
  className,
  params,
  scrollwheel = true,
}: {
  id: any;
  title: string;
  className: string;
  params?: ReportParam[];
  scrollwheel?: boolean;
}) {
  const API_KEY = "";

  const { database, darkMode } = useAppSelector(toggleSelector);
  const { user } = useAppSelector(authSelector);
  const [db, setDb] = useState<Database>(user?.defaultDatabase as Database);
  const { showMap } = useAppSelector(dashboardSelector);
  const [error, setError] = useState<null | string>(null);
  const dispatch = useAppDispatch();

  const [activeMarker, setActiveMarker] = useState<null | number>(null);
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(() => {
    setIsLoading(true);
    if (id && id === 0) {
      console.log("Info ID is 0");
      setError("Info ID is 0");
      setIsLoading(false);
      return;
    }
    if (db && db.id === 0) {
      console.log("Database ID is 0");
      setError("Database ID is 0");
      setIsLoading(false);
      return;
    }
    if (id && db && db.id) {
      RunMap(id, db.id, params)
        .then((response) => {
          if (response.status === 200) {
            setMarkers(response.data);
            setIsLoading(false);
          } else {
            setError(response.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [markers]);

  const onCardClose = () => {
    dispatch(setShowMap(showMap));
  };

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <CollapsibleCard
      title={title}
      className={className}
      isLoading={isLoading}
      onReload={loadData}
      onClose={onCardClose}
    >
      <div
        style={{
          position: "relative",
          height: "300px",
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        <APIProvider apiKey={API_KEY} region='AZ'>
          <Map
            defaultCenter={{ lat: 40.3963856, lng: 49.8720056 }}
            defaultZoom={6}
            gestureHandling={"greedy"}
            disableDefaultUI={false}
            scrollwheel={scrollwheel}
            fullscreenControl={true}
            mapId={darkMode ? "739af084373f96fe" : "49ae42fed52588c3"}
            mapTypeId={"roadmap"}
          >
            {markers.map((marker, index) => (
              <span key={index}>
                <Marker
                  key={marker.name}
                  position={{
                    lat: +marker.position.lat,
                    lng: +marker.position.lng,
                  }}
                  onClick={() => {
                    setActiveMarker(index);
                  }}
                />
                {activeMarker === index && (
                  <InfoWindow
                    position={{
                      lat: +marker.position.lat,
                      lng: +marker.position.lng,
                    }}
                    onCloseClick={() => setActiveMarker(null)}
                  >
                    <p
                      className='text-center'
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      {marker.name}
                    </p>
                    <p
                      className='text-center'
                      style={{
                        color: "black",
                      }}
                    >
                      {marker.description}
                    </p>
                  </InfoWindow>
                )}
              </span>
            ))}
          </Map>
        </APIProvider>
      </div>
    </CollapsibleCard>
  );
}
