import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CollapsibleCard from "../../components/CollapsibleCard";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import "@sweetalert2/themes/dark/dark.css";
import { DeleteReport, GetReports } from "../../services/ReportService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Inputs/Input";
import HasAccess from "../auth/protected-components";

export default function ReportList() {
  const navigate = useNavigate();
  const initData: ReportData[] = [];

  const [data, setData] = React.useState<ReportData[]>(initData);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");

  const MySwal = withReactContent(Swal);

  const deleteHandler = (id: number) => {
    if (id === 0) {
      return;
    }
    MySwal.fire({
      title: "Silmək istədiyinizdən əminsinizmi?",
      showCancelButton: true,
      cancelButtonText: "Xeyr",
      cancelButtonColor: "#f93154",
      confirmButtonColor: "#00b74a",
      confirmButtonText: "Bəli",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteReport(id)
          .then((res) => {
            console.log(res);
            if (res.status === 204) {
              toast.success(res.message);
              getData();
            } else {
              toast.error(res.message);
            }
          })
          .catch((e) => toast.error(e.message));
      }
    });
  };

  const getData = (s?: string) => {
    setLoading(true);
    GetReports({ q: s }).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  const addNew = () => {
    navigate("/reports/add");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <CollapsibleCard
      title='Reports'
      onAdd={addNew}
      onReload={() => getData()}
      isLoading={loading}
    >
      <div className='row ml-3'>
        <Input
          label='Search'
          icon='search'
          placeholder='Search in reports'
          infotext='Enter report name to search'
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          btnClick={() => getData(search)}
          btncolor='btn-outline-secondary'
          btnname='Search'
        />
      </div>
      {!data ||
        (data.length === 0 && (
          <h5 className='text-center'>There is no data.</h5>
        ))}
      {data && data.length > 0 && (
        <>
          <table className='table table-responsive table-striped projects'>
            <thead>
              <tr>
                <th style={{ width: "1%" }}>#</th>
                <th style={{ width: "20%" }}>Report Name</th>
                <th style={{ width: "30%" }}>Description</th>
                <th style={{ width: "20%" }}>Roles</th>
                <th style={{ width: "5%" }}>Type</th>
                <th style={{ width: "5%" }}>Active/Passive</th>
                <th style={{ width: "20%" }}></th>
              </tr>
            </thead>
            <tbody>
              {data.map((r, i) => (
                <tr key={r.id}>
                  <td>{r.id}</td>
                  <td>
                    <a>{r.title}</a>
                    <br />
                  </td>
                  <td>
                    <p>{r.description}</p>
                  </td>
                  <td>
                    <span className='no-select badge badge-secondary'>
                      {r.roles}
                    </span>
                  </td>
                  <td>
                    <p>{r.reportType}</p>
                  </td>
                  <td>
                    <p>{r.active ? "Active" : "Passive"}</p>
                  </td>
                  <td className='project-actions text-right'>
                    <NavLink
                      className='btn btn-primary btn-sm ml-1 mb-1'
                      to={"/reports/" + r.id}
                    >
                      <FontAwesomeIcon icon={faEye} className='mr-1' />
                      View
                    </NavLink>
                    <HasAccess roles={["admin"]}>
                      <NavLink
                        className='btn btn-info btn-sm ml-1 mb-1'
                        to={"/reports/" + r.id + "/edit"}
                      >
                        <FontAwesomeIcon icon={faEdit} className='mr-1' />
                        Edit
                      </NavLink>
                    </HasAccess>
                    <HasAccess roles={["admin"]}>
                      <button
                        type='button'
                        className='btn btn-danger btn-sm ml-1 mb-1'
                        onClick={() => deleteHandler(r.id ?? 0)}
                      >
                        <FontAwesomeIcon icon={faTrash} className='mr-1' />
                        Delete
                      </button>
                    </HasAccess>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </CollapsibleCard>
  );
}
