import { Navigate } from "react-router-dom";
import App from "../../App";
import { useAuth } from "../../utils/AuthContext";
import { useEffect } from "react";
import { VerifyToken } from "../../services/AuthService";
import { GetUser } from "../../services/UserService";
import { setUser } from "../../store/slices/AuthSlice";
import { useAppDispatch } from "../../utils/Hooks";

const ProtectedRoutes = () => {
  const { token, setToken } = useAuth();
  const dispatch = useAppDispatch();

  const vrft = () => {
    VerifyToken(token)
      .then((response) => {
        GetUser(+response.data.payload.userId)
          .then((response) => {
            dispatch(setUser(response.data));
          })
          .catch((error) => {
            console.log(error);
            setToken(null);
          });
      })
      .catch((error) => {
        console.log(error);
        setToken(null);
      });
  };

  useEffect(() => {
    vrft();
  }, [token]);

  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to='/login' replace />;
  }

  // If authenticated, render the child routes
  return <App />;
};

export default ProtectedRoutes;
