import { REPORTS_URL, REPORT_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetReports(qparams?: any) {
  return axios
    .get(REPORTS_URL, { params: { ...qparams }})
    .then((response): ResponseModel<ReportData[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<ReportData[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetReport(id: number) {
  return axios
    .get(REPORT_URL + `${id}`)
    .then((response): ResponseModel<ReportData> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function RunReport(
  id: number,
  dbId: number,
  params?: ReportParam[],
  signal?: AbortSignal
) {
  return axios
    .post(
      REPORT_URL + `${id}` + "/run",
      { id: id, dbId: dbId, params: params },
      { signal }
    )
    .then((response): ResponseModel<ReportData> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateReport(id: any, report: ReportData) {
  return axios
    .put(REPORT_URL + `${id}`, report)
    .then((response): ResponseModel<ReportData> => {
      let report = response.data as ReportData;
      return {
        data: response.data,
        status: response.status,
        message: `${report.title} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertReport(report: ReportData) {
  return axios
    .post(REPORT_URL, report)
    .then((response): ResponseModel<ReportData> => {
      let report = response.data as ReportData;
      return {
        data: response.data,
        status: response.status,
        message: `${report.title} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteReport(id: number) {
  return axios
    .delete(REPORT_URL + `${id}`)
    .then((response): ResponseModel<ReportData> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
