import React, { useEffect } from "react";
import Switch from "../../../components/Inputs/Switch";
import Input from "../../../components/Inputs/Input";
import Select from "../../../components/Inputs/Select";
import CollapsibleCard from "../../../components/CollapsibleCard";
import { useNavigate, useParams } from "react-router-dom";
import TableAvatar from "../../../components/TableAvatar/TableAvatar";
import { GetUser } from "../../../services/UserService";
import { GetRoles } from "../../../services/RoleService";

export default function UserView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userData: User = {
    isActive: false,
    displayName: "",
    username: "",
    role: {} as Role,
    email: "",
    phoneNumber: "",
    avatar: "",
    defaultDatabase: {} as Database,
  };
  const [user, setUser] = React.useState<User>(userData);
  const [roles, setRoles] = React.useState<Role[]>([]);

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/users")}
      >
        Back
      </button>
    </div>
  );

  useEffect(() => {
    if (id) {
      GetUser(Number(id)).then((response) => {
        setUser(response.data);
      });
    }
  }, [id]);

  useEffect(() => {
    GetRoles().then((response) => {
      setRoles(response.data);
    });
  }, []);
  return (
    <CollapsibleCard
      title={"View : " + user.displayName}
      footer={_footerContent}
    >
      <div className='m-2'>
        <Input
          id='username'
          label='Username'
          placeholder=''
          name='username'
          icon='user'
          type='text'
          value={user.username}
          onChange={() => {}}
          disabled
        />
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='displayName'
              label='Display Name'
              placeholder=''
              name='displayName'
              icon='pencil-alt'
              type='text'
              value={user.displayName ?? ""}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className='col-6'>
            <Select
              label='Role'
              name='role'
              icon='check-double'
              options={roles}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              value={roles?.find((o) => o.id === user.role.id)}
              onChange={() => {}}
              disabled
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='email'
              label='Email'
              placeholder=''
              name='email'
              icon='envelope'
              type='text'
              value={user.email ?? ""}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className='col-6'>
            <Input
              id='phoneNumber'
              label='Phone Number'
              placeholder=''
              name='phoneNumber'
              icon='phone'
              type='text'
              value={user.phoneNumber ?? ""}
              onChange={() => {}}
              disabled
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='defaultDatabase'
              label='Default Database'
              placeholder=''
              name='defaultDatabase'
              icon='database'
              type='text'
              value={user.defaultDatabase?.name}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className='col-6'>
            <Input
              id='databases'
              label='Accessible Databases'
              placeholder=''
              name='databases'
              icon='database'
              type='text'
              value={user.databases?.map((d) => d.name).join(", ")}
              onChange={() => {}}
              disabled
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='ml-2'>
              <TableAvatar user={user} style={{ width: "80px" }} />
            </div>
          </div>
          <div className='col-6'>
            <Switch
              label='Active/Passive'
              id='isActive'
              name='isActive'
              value={user.isActive}
              onChange={() => {}}
              disabled
            />
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
