import {
  CHARTS_URL,
  DASHBOARD_URL,
  INFOCARDS_URL,
  MAPS_URL,
} from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetCharts(qparams?: any) {
  return axios
    .get(CHARTS_URL, { params: { ...qparams } })
    .then((response): ResponseModel<Chart[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Chart[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetInfoCards(qparams?: any) {
  return axios
    .get(INFOCARDS_URL, { params: { ...qparams } })
    .then((response): ResponseModel<InfoCard[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<InfoCard[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetMaps(qparams?: any) {
  return axios
    .get(MAPS_URL, { params: { ...qparams } })
    .then((response): ResponseModel<DashMap[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<DashMap[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function RunMap(
  id: number,
  dbId: number,
  params?: ReportParam[],
  signal?: AbortSignal
) {
  return axios
    .post(
      DASHBOARD_URL + "map/" + `${id}`,
      {
        id: id,
        dbId: dbId,
        params: params,
      },
      { signal: signal }
    )
    .then((response): ResponseModel<MapMarker[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<MapMarker[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function RunInfoCard(
  id: number,
  dbId: number,
  params?: ReportParam[],
  signal?: AbortSignal
) {
  return axios
    .post(
      DASHBOARD_URL + "infocard/" + `${id}`,
      {
        id: id,
        dbId: dbId,
        params: params,
      },
      { signal: signal }
    )
    .then((response): ResponseModel<InfoCard> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<InfoCard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function RunChart(
  id: number,
  dbId: number,
  params?: ReportParam[],
  signal?: AbortSignal
) {
  return axios
    .post(
      DASHBOARD_URL + "chart/" + `${id}`,
      {
        id: id,
        dbId: dbId,
        params: params,
      },
      { signal: signal }
    )
    .then((response): ResponseModel<ReportData> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<ReportData> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetActiveDashboard() {
  return axios
    .get(DASHBOARD_URL)
    .then((response): ResponseModel<Dashboard> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Dashboard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetDashboard(id: number) {
  return axios
    .get(DASHBOARD_URL + `${id}`)
    .then((response): ResponseModel<Dashboard> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Dashboard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateDashboard(id: any, dashboard: Dashboard) {
  return axios
    .put(DASHBOARD_URL + `${id}`, dashboard)
    .then((response): ResponseModel<Dashboard> => {
      let dashboard = response.data as Dashboard;
      return {
        data: response.data,
        status: response.status,
        message: `${dashboard.title} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<Dashboard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertDashboard(dashboard: Dashboard) {
  return axios
    .post(DASHBOARD_URL, dashboard)
    .then((response): ResponseModel<Dashboard> => {
      let dashboard = response.data as Dashboard;
      return {
        data: response.data,
        status: response.status,
        message: `${dashboard.title} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<Dashboard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteDashboard(id: number) {
  return axios
    .delete(DASHBOARD_URL + `${id}`)
    .then((response): ResponseModel<Dashboard> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<Dashboard> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
