import { authSelector } from "../../store/slices/AuthSlice";
import { useAppSelector } from "../../utils/Hooks";

export default function UserPanel() {
  const { user } = useAppSelector(authSelector);
  return (
    <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
      <div className='image'>
        <img
          src={user?.avatar ? user.avatar.toString() : "/img/avatar.png"}
          className='img-circle elevation-2'
          alt='User Image'
        />
      </div>
      <div className='info'>
        <a href='#' className='d-block'>
          {user?.displayName ?? user?.username}
        </a>
      </div>
    </div>
  );
}
