import { useMemo, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filter from "./Filter";
import Pagination from "./Pagination";
import { Capitalize } from "../../utils/extensions";
import { exportToCSV, exportToExcel } from "../../utils/exportUtils";

function CustomDataTable({ data }: { data: any[] }) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo<ColumnDef<any, any>[]>(
    () =>
      data.length > 0
        ? Object.keys(data[0]).map((d: any, index: number) => ({
            accessorKey: d,
            header: () => <span>{Capitalize(d)}</span>,
            cell: (info) => info.getValue(),
          }))
        : [],
    [data]
  );

  const tableData = useMemo(() => data.map((row) => ({ ...row })), [data]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const handleExportXls = () => {
    exportToExcel(data, "report-xls");
  };
  const handleExportCsv = () => {
    exportToCSV(data, "report-csv");
  };

  return (
    <>
      <div className='mb-2 ml-2'>
        <button className='btn btn-primary' onClick={handleExportXls}>
          <FontAwesomeIcon icon={["fas", "file-excel"]} className='mr-2' />
          Export to Excell
        </button>
        <button className='btn btn-primary mx-2' onClick={handleExportCsv}>
          <FontAwesomeIcon icon={["fas", "file-csv"]} className='mr-2' />
          Export to CSV
        </button>
      </div>
      <table className='table table-responsive table-striped projects'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: "10%" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer no-select"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <span className='ml-2'>
                                <FontAwesomeIcon icon={["fas", "sort-up"]} />
                              </span>
                            ),
                            desc: (
                              <span className='ml-2'>
                                <FontAwesomeIcon icon={["fas", "sort-down"]} />
                              </span>
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination table={table} />
    </>
  );
}

export default CustomDataTable;
