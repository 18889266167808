import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import {
  dashboardSelector,
  setAvailableInfoCards,
} from "../../store/slices/DashboardSlice";
import { GetInfoCards } from "../../services/DashboardService";
import InfoCard from "../InfoCard";

export default function InfoCardContainer() {
  const { infoCards } = useAppSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const [grouppedInfoCards, setGrouppedInfoCards] = useState<any[]>([]);

  const loadInfoCards = async () => {
    console.log();
    try {
      await GetInfoCards().then((res) => {
        if (res.status === 200) {
          dispatch(setAvailableInfoCards(res.data));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const initLayout = () => {
    if (infoCards && infoCards.length > 0) {
      const grp = [];
      for (let i = 0; i < infoCards.length; i += 4) {
        grp.push(infoCards.slice(i, i + 4));
      }
      setGrouppedInfoCards(grp);
    }
  };

  useEffect(() => {
    initLayout();
  }, [infoCards]);

  useEffect(() => {
    loadInfoCards();
    //return () => controller.abort();
  }, []);

  return (
    <>
      {grouppedInfoCards.map((group, index) => (
        <div className='row' key={index}>
          {group.map((infoCard: InfoCard, idx: number) => (
            <InfoCard key={idx} id={infoCard.id} />
          ))}
        </div>
      ))}
    </>
  );
}
