import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
type InputProps = {
  name?: string;
  id?: string;
  placeholder?: string;
  value?: any;
  label: string;
  icon?: IconName;
  [x: string]: any;
};

export default function RichEditor(input: InputProps) {
  const { ...rest } = input;
  const [value, setValue] = useState(input.value);
  const icon: IconName = input.icon ? input.icon : "circle";

  return (
    <div className='form-group'>
      <label htmlFor={input.id}>{input.label}</label>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            <FontAwesomeIcon icon={["fas", icon]} />
          </span>
        </div>
        <textarea
          name={input.name}
          className='form-control'
          id={input.id}
          placeholder={input.placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...rest}
        />
      </div>
    </div>
  );
}
