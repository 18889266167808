import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CollapsibleCard from "../../components/CollapsibleCard";
import { GetReport, UpdateReport } from "../../services/ReportService";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../components/Inputs/Input";
import RichEditor from "../../components/Inputs/RichEditor/RichEditor";
import Select from "../../components/Inputs/Select";
import { GetRoles } from "../../services/RoleService";
import KeyValueInputTable from "../../components/ReportParams/KeyValueInputTable";
import CustomCodeEditor from "../../components/Inputs/CustomCodeEditor";
import MachedParams from "../../components/ReportParams/MachedParams";
import Switch from "../../components/Inputs/Switch";

export default function ReportEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const formData: ReportData = {
    title: "",
    description: "",
    sqlQuery: "",
    roles: "[admin]",
    sqlParams: "[]",
    reportType: "report",
    active: true,
    hasMap: false,
  };
  const [data, setData] = useState<ReportData>(formData);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleValue, setRoleValue] = useState<Role[]>([]);

  const updateHandler = (data: ReportData) => {
    setLoading(true);
    UpdateReport(id, data).then((response) => {
      if (response.status === 200) {
        toast.success(`${data.title} updated successfully.`);
        navigate("/reports/list");
      } else {
        toast.error(`${response.message}`);
      }
    });
    setLoading(false);
  };

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/reports/list")}
      >
        Cancel
      </button>
      <button
        className='btn btn-outline-success'
        onClick={() => updateHandler(data)}
      >
        {loading ? <FontAwesomeIcon icon='spinner' spin /> : "Save"}
      </button>
    </div>
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      GetReport(+id).then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    setRoleValue(
      data.roles.split(",").map((role) => {
        return roles.find((r: Role) => r.name.toLowerCase() === role) as Role;
      })
    );
  }, [data]);

  useEffect(() => {
    setLoading(true);
    GetRoles().then((response) => {
      setRoles(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <CollapsibleCard
      title={"Edit : " + data.title}
      footer={_footerContent}
      isLoading={loading}
    >
      <div className='m-2'>
        <div className='row'>
          <div className='col-10'>
            <Input
              id='title'
              label='Title'
              placeholder='Enter Title'
              name='title'
              icon='pencil-alt'
              type='text'
              value={data.title}
              onChange={(e: any) => setData({ ...data, title: e.target.value })}
            />
          </div>
          <div className='col-2'>
            <Switch
              label='Active/Passive'
              id='active'
              name='active'
              value={data.active}
              setValue={() => setData({ ...data, active: !data.active })}
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <RichEditor
          id='description'
          label='Description'
          name='description'
          placeholder='Enter Description'
          icon='spell-check'
          value={data.description}
          onChange={(e: any) =>
            setData({ ...data, description: e.target.value })
          }
        />
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='icon'
              label='Icon'
              infotext='Enter FontAwesome Icon Name'
              placeholder='Enter FontAwesome Icon Name'
              name='icon'
              icon='font-awesome'
              type='text'
              value={data.icon}
              onChange={(e: any) => setData({ ...data, icon: e.target.value })}
            />
          </div>
          <div className='col-6'>
            <Select
              label='Role'
              name='role'
              icon='check-double'
              options={roles}
              isMulti={true}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              value={roleValue}
              onChange={(value: Role[]) =>
                setData({
                  ...data,
                  roles: value.map((role) => role.name.toLowerCase()).join(","),
                })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <div className='form-group'>
              <label htmlFor='reportType'>Type</label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    {data.reportType === "report" && (
                      <FontAwesomeIcon icon={["fas", "table-columns"]} />
                    )}
                    {data.reportType === "chart" && (
                      <FontAwesomeIcon icon={["fas", "chart-simple"]} />
                    )}
                    {data.reportType === "infocard" && (
                      <FontAwesomeIcon icon={["fas", "circle-info"]} />
                    )}
                    {data.reportType === "map" && (
                      <FontAwesomeIcon icon={["fas", "map"]} />
                    )}
                    {!data.reportType && (
                      <FontAwesomeIcon icon={["fas", "table-columns"]} />
                    )}
                  </span>
                </div>
                <select
                  className='form-control'
                  id='reportType'
                  value={data.reportType}
                  onChange={(e) =>
                    setData({ ...data, reportType: e.target.value })
                  }
                >
                  <option value='report'>Report</option>
                  <option value='chart'>Chart</option>
                  <option value='infocard'>Info Card</option>
                  <option value='map'>Map</option>
                </select>
              </div>
            </div>
          </div>
          <div className='col-6'>
            {data.reportType === "chart" && (
              <div className='form-group'>
                <label htmlFor='reportType'>Chart Type</label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      {data.chartType === "pie" && (
                        <FontAwesomeIcon icon={["fas", "chart-pie"]} />
                      )}
                      {data.chartType === "area" && (
                        <FontAwesomeIcon icon={["fas", "chart-area"]} />
                      )}
                      {data.chartType === "line" && (
                        <FontAwesomeIcon icon={["fas", "chart-line"]} />
                      )}
                      {data.chartType === "bar" && (
                        <FontAwesomeIcon icon={["fas", "chart-bar"]} />
                      )}
                      {!data.chartType && (
                        <FontAwesomeIcon icon={["fas", "chart-area"]} />
                      )}
                    </span>
                  </div>
                  <select
                    className='form-control'
                    id='chartType'
                    value={data.chartType}
                    onChange={(e) =>
                      setData({ ...data, chartType: e.target.value })
                    }
                  >
                    <option value='area'>Area</option>
                    <option value='line'>Line</option>
                    <option value='pie'>Pie</option>
                    <option value='bar'>Bar</option>
                  </select>
                </div>
              </div>
            )}
            {data.reportType === "infocard" && (
              <div className='form-group'>
                <label htmlFor='reportType'>Color</label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      {data.color === "bg-info" && (
                        <FontAwesomeIcon
                          icon={["fas", "circle-info"]}
                          className='text-info'
                        />
                      )}
                      {data.color === "bg-warning" && (
                        <FontAwesomeIcon
                          icon={["fas", "circle-info"]}
                          className='text-warning'
                        />
                      )}
                      {data.color === "bg-danger" && (
                        <FontAwesomeIcon
                          icon={["fas", "circle-info"]}
                          className='text-danger'
                        />
                      )}
                      {data.color === "bg-success" && (
                        <FontAwesomeIcon
                          icon={["fas", "circle-info"]}
                          className='text-success'
                        />
                      )}
                      {!data.color && (
                        <FontAwesomeIcon icon={["fas", "circle-info"]} />
                      )}
                    </span>
                  </div>
                  <select
                    className='form-control'
                    id='color'
                    value={data.color}
                    onChange={(e) =>
                      setData({ ...data, color: e.target.value })
                    }
                  >
                    <option value='bg-info'>Blue</option>
                    <option value='bg-warning'>Yellow</option>
                    <option value='bg-danger'>Red</option>
                    <option value='bg-success'>Green</option>
                  </select>
                </div>
              </div>
            )}
            {data.reportType === "report" && (
              <div className='form-group'>
                <Switch
                  label='With Map'
                  id='hasMap'
                  name='hasMap'
                  value={data.hasMap}
                  setValue={() => setData({ ...data, hasMap: !data.hasMap })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='m-2'>
        <CustomCodeEditor
          language='sql'
          id='sqlQuery'
          label='SQL Query'
          infotext='Parameters must be start with : (colon)'
          name='sqlQuery'
          placeholder='Enter Query'
          icon='code'
          value={data.sqlQuery}
          onChange={(e: any) => setData({ ...data, sqlQuery: e.target.value })}
        />
      </div>
      <div className='m-2'>
        <MachedParams code={data.sqlQuery ?? ""} />
      </div>
      <div className='m-2'>
        <KeyValueInputTable
          label='Parameters'
          value={data.sqlParams}
          onValueChange={(value: string) =>
            setData({ ...data, sqlParams: value })
          }
        />
      </div>
    </CollapsibleCard>
  );
}
