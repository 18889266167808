import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  toggleDatabase,
  toggleDatabaseChanger,
  toggleSelector,
} from "../../store/slices/ToggleSlice";
import { useAppSelector } from "../../utils/Hooks";
import { authSelector } from "../../store/slices/AuthSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

export default function DatabaseSelector() {
  const { t, i18n } = useTranslation(["translation", "content"]);
  const dispatch = useDispatch<any>();
  const { databaseChanger, database } = useAppSelector(toggleSelector);
  const { user } = useAppSelector(authSelector);

  const changeHandler = (database: Database) => {
    dispatch(toggleDatabaseChanger(databaseChanger));
    dispatch(toggleDatabase(database));
  };

  useEffect(() => {
    if (database === null) {
      dispatch(toggleDatabase(user?.defaultDatabase));
    }
  }, []);

  return (
    <div className={"nav-item dropdown " + (databaseChanger ? "show" : "")}>
      <span
        onClick={() => {
          dispatch(toggleDatabaseChanger(databaseChanger));
        }}
        className='nav-link'
      >
        <FontAwesomeIcon icon={faDatabase} fade={database ? false : true} />
        &nbsp;
        {isMobile ? database?.name.slice(0, 3) + "..." : database?.name}
      </span>

      <div
        className={
          "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
          (databaseChanger ? "show" : "")
        }
      >
        {user?.databases?.map((db: Database) => {
          return (
            <span
              key={db.id}
              className='dropdown-item'
              onClick={() => {
                changeHandler(db);
              }}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <span> {db.name}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
