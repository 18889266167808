import { authSelector } from "../../store/slices/AuthSlice";
import { useAppSelector } from "../../utils/Hooks";

export default function HasAccess({
  children: children,
  roles: roles,
}: {
  children: React.ReactNode;
  roles: string[];
}) {
  const { user } = useAppSelector(authSelector);

  if (!user) {
    return <></>;
  }

  if (!roles) {
    return <></>;
  }

  if (!roles.includes(user.role.name.toLowerCase())) {
    return <></>;
  }

  if (!children) {
    return <></>;
  }

  return <>{children}</>;
}
