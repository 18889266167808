import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CollapsibleCard from "../../../components/CollapsibleCard";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import TableAvatar from "../../../components/TableAvatar/TableAvatar";
import { DeleteUser, GetUsers } from "../../../services/UserService";
import Input from "../../../components/Inputs/Input";
import HasAccess from "../../auth/protected-components";

export default function UserList() {
  const navigate = useNavigate();
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");

  const MySwal = withReactContent(Swal);

  const deleteHandler = (id: number) => {
    if (id === 0) {
      return;
    }
    MySwal.fire({
      title: "Silmək istədiyinizdən əminsinizmi?",
      showCancelButton: true,
      cancelButtonText: "Xeyr",
      cancelButtonColor: "#f93154",
      confirmButtonColor: "#00b74a",
      confirmButtonText: "Bəli",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteUser(id)
          .then((res) => {
            if (res.status === 204) {
              toast.success(res.message);
              getData();
            } else {
              toast.error(res.message);
            }
          })
          .catch((e) => console.error(e));
      }
    });
  };

  const getData = (s?: string) => {
    setLoading(true);
    GetUsers({ q: s }).then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  };

  const addNew = () => {
    navigate("/users/add");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <CollapsibleCard
        title='Users'
        onAdd={addNew}
        onReload={() => getData()}
        isLoading={loading}
      >
        <div className='row ml-3'>
          <Input
            label='Search'
            icon='search'
            placeholder='Search in users'
            infotext='Enter user name or display name to search'
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            btnClick={() => getData(search)}
            btncolor='btn-outline-secondary'
            btnname='Search'
          />
        </div>
        {!users ||
          (users.length === 0 && (
            <h5 className='text-center'>There is no data.</h5>
          ))}
        {users && users.length > 0 && (
          <table className='table table-responsive table-striped projects'>
            <thead>
              <tr>
                <th style={{ width: "1%" }}>#</th>
                <th style={{ width: "4%" }}>Avatar</th>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "20%" }}>Info</th>
                <th style={{ width: "8%" }} className='text-center'>
                  Role
                </th>
                <th style={{ width: "8%" }} className='text-center'>
                  Active/Passive
                </th>
                <th style={{ width: "20%" }} className='text-center'>
                  Operations
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((u, i) => (
                <tr key={u.id}>
                  <td>{u.id}</td>
                  <td>
                    <TableAvatar user={u} />
                  </td>
                  <td>
                    <a>{u.displayName}</a>
                    <br />
                    <small>Username : {u.username}</small>
                  </td>
                  <td>
                    <a>{u.phoneNumber}</a>
                    <br />
                    <small>{u.email}</small>
                  </td>
                  <td className='project-state'>
                    <span className='no-select badge badge-info'>
                      {u.role.name}
                    </span>
                  </td>
                  <td className='project-state'>
                    <span
                      className={
                        "no-select badge " +
                        (u.isActive ? "badge-success" : "badge-danger")
                      }
                    >
                      {u.isActive ? "Active" : "Passive"}
                    </span>
                  </td>
                  <td className='project-actions text-right'>
                    <NavLink
                      to={`/users/${u.id}`}
                      className='btn btn-primary btn-sm ml-1 mb-1'
                    >
                      <FontAwesomeIcon icon={faEye} className='mr-1' />
                      View
                    </NavLink>
                    <HasAccess roles={["admin"]}>
                      <NavLink
                        to={`/users/${u.id}/edit`}
                        className='btn btn-info btn-sm ml-1 mb-1'
                      >
                        <FontAwesomeIcon icon={faEdit} className='mr-1' />
                        Edit
                      </NavLink>
                    </HasAccess>
                    <HasAccess roles={["admin"]}>
                      <button
                        type='button'
                        className='btn btn-danger btn-sm ml-1 mb-1'
                        onClick={() => deleteHandler(u.id ?? 0)}
                      >
                        <FontAwesomeIcon icon={faTrash} className='mr-1' />
                        Delete
                      </button>
                    </HasAccess>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </CollapsibleCard>
    </>
  );
}
