import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Signin } from "../../services/AuthService";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAuth } from "../../utils/AuthContext";

export default function Login() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    Signin({ username, password })
      .then(({ data, status, message }) => {
        if (status === 401 || status === 400) {
          toast.error(message);
        } else {
          toast.success(`Uğurla giriş etdi.`);
          setToken(data);
          navigate("/");
        }
      })
      .catch((e) => {
        toast.error("API Server Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Toaster />
      <div className='hold-transition login-page'>
        <div className='login-box'>
          <div className='login-logo'>
            <b>CAHAN</b>Reports
          </div>
          <div className='card'>
            <div className='card-body login-card-body'>
              <p className='login-box-msg'>Sign in to start your session</p>

              <form onSubmit={handleSubmit} method='post'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text'>
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                </div>
                <div className='input-group mb-3'>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text'>
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
