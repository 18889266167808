import CollapsibleCard from "../../components/CollapsibleCard";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import {
  addChart,
  addInfoCards,
  addMaps,
  dashboardSelector,
  removeChart,
  removeInfoCards,
  removeMaps,
} from "../../store/slices/DashboardSlice";
import DualList from "../../components/Inputs/DualList";
import { authSelector } from "../../store/slices/AuthSlice";

export default function Settings() {
  const { allCharts, charts, allInfoCards, infoCards, allMaps, maps } =
    useAppSelector(dashboardSelector);
  const { user } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  return (
    <>
      <CollapsibleCard title={"Dashboard Settings"}>
        <div className='ml-4 mr-4'>
          <div className='row'>
            <div className='col-12 col-md-4 col-lg-4 col-sm-6'>
              <DualList
                label={"Charts"}
                options={allCharts}
                selected={charts}
                getOptionLabel={"title"}
                getOptionValue={"id"}
                setSelected={(selected: Chart) => {
                  dispatch(addChart(selected));
                }}
                onRemove={(selected: Chart) => {
                  dispatch(removeChart(selected.id));
                }}
              />
            </div>
            <div className='col-12 col-md-4 col-lg-4 col-sm-6'>
              <DualList
                label={"Info Cards"}
                options={allInfoCards}
                selected={infoCards}
                getOptionLabel={"title"}
                getOptionValue={"id"}
                setSelected={(selected: InfoCard) => {
                  dispatch(addInfoCards(selected));
                }}
                onRemove={(selected: InfoCard) => {
                  dispatch(removeInfoCards(selected.id));
                }}
              />
            </div>
            <div className='col-12 col-md-4 col-lg-4 col-sm-6'>
              <DualList
                label={"Maps"}
                options={allMaps}
                selected={maps}
                getOptionLabel={"title"}
                getOptionValue={"id"}
                setSelected={(selected: DashMap) => {
                  dispatch(addMaps(selected));
                }}
                onRemove={(selected: DashMap) => {
                  dispatch(removeMaps(selected.id));
                }}
              />
            </div>
          </div>

          <div className='row'></div>
        </div>
      </CollapsibleCard>
      {user && user.role.name.toLowerCase() === "admin" && (
        <CollapsibleCard title={"Settings"}>
          <div className='ml-4 mr-4'></div>
        </CollapsibleCard>
      )}
    </>
  );
}
