import React, { useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../../components/Inputs/Select";
import CollapsibleCard from "../../../components/CollapsibleCard";
import Switch from "../../../components/Inputs/Switch";
import InputFile from "../../../components/Inputs/InputFile";
import { GetUser, UpdateUser } from "../../../services/UserService";
import { GetRoles } from "../../../services/RoleService";
import DatabaseSelect from "../../../components/Inputs/DatabaseSelect";
import { GetDatabases } from "../../../services/DatabaseService";
import {
  GetBolgeler,
  GetDepolar,
  GetPersoneller,
  GetQruplar,
  GetSektorlar,
  GetSrmMerkezler,
} from "../../../services/MikroService";
import { useAppSelector } from "../../../utils/Hooks";
import { toggleSelector } from "../../../store/slices/ToggleSlice";

export default function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userData: User = {
    isActive: false,
    displayName: "",
    username: "",
    role: {} as Role,
    email: "",
    phoneNumber: "",
    defaultDatabase: {} as Database,
    mikroParams: {} as MikroParams,
  };
  const [user, setUser] = useState<User>(userData);
  const [roles, setRoles] = useState<Role[]>([]);
  const [databases, setDatabases] = useState<Database[]>([]);
  const [loading, setLoading] = useState(false);

  const { database } = useAppSelector(toggleSelector);

  const [selectBoxLoading, setSelectBoxLoading] = useState(false);
  const [sectorlar, setSectorlar] = useState<Sektorlar[]>([]);
  const [qruplar, setQruplar] = useState<Qruplar[]>([]);
  const [bolgeler, setBolgeler] = useState<Bolgeler[]>([]);
  const [personeller, setPersoneller] = useState<Personeller[]>([]);
  const [depolar, setDepolar] = useState<Depolar[]>([]);
  const [merkezler, setMerkezler] = useState<Merkezler[]>([]);

  const loadSelectBoxes = (dbId: number) => {
    setSelectBoxLoading(true);
    GetDepolar({ dbId: dbId }).then((response) => {
      setDepolar(response.data);
    });

    GetSrmMerkezler({ dbId: dbId }).then((response) => {
      setMerkezler(response.data);
    });

    GetBolgeler({ dbId: dbId }).then((response) => {
      setBolgeler(response.data);
    });

    GetQruplar({ dbId: dbId }).then((response) => {
      setQruplar(response.data);
    });

    GetPersoneller({ dbId: dbId }).then((response) => {
      setPersoneller(response.data);
    });

    GetSektorlar({ dbId: dbId }).then((response) => {
      setSectorlar(response.data);
    });

    setSelectBoxLoading(false);
  };

  const editUser = (data: User) => {
    setLoading(true);
    console.log(data);
    UpdateUser(data.id, data).then((response) => {
      if (response.status === 200) {
        toast.success(`${data.username} yeniləndi.`);
        navigate("/users");
      }
    });
    setLoading(false);
  };

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/users")}
      >
        Cancel
      </button>
      <button
        className='btn btn-outline-success'
        onClick={() => editUser(user)}
      >
        Save
      </button>
    </div>
  );

  useEffect(() => {
    if (id) {
      GetUser(Number(id)).then((response) => {
        setUser(response.data);
      });
    }
  }, [id]);

  useEffect(() => {
    GetRoles().then((response) => {
      setRoles(response.data);
    });
  }, []);

  useEffect(() => {
    GetDatabases().then((response) => {
      setDatabases(response.data);
    });
  }, []);

  useEffect(() => {
    if (database) {
      loadSelectBoxes(database.id ?? 0);
    }
  }, []);

  return (
    <CollapsibleCard
      title={"Edit : " + user.displayName}
      footer={_footerContent}
      isLoading={loading}
    >
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='displayName'
              label='Display Name'
              placeholder='Enter Display Name'
              name='displayName'
              icon='pencil-alt'
              type='text'
              value={user.displayName}
              onChange={(e: any) =>
                setUser({ ...user, displayName: e.target.value })
              }
            />
          </div>
          <div className='col-6'>
            {roles && (
              <Select
                label='Role'
                name='role'
                icon='check-double'
                options={roles}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                defaultValue={{ label: "Select Role", value: 0 }}
                onChange={(value: any) => setUser({ ...user, role: value })}
                value={roles?.find((o) => o.id === user.role.id)}
              />
            )}
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='email'
              label='Email'
              placeholder='Enter email'
              name='email'
              icon='envelope'
              type='text'
              value={user.email}
              onChange={(e: any) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className='col-6'>
            <Input
              id='phoneNumber'
              label='Phone Number'
              placeholder='Enter phone number'
              name='phoneNumber'
              icon='phone'
              type='text'
              value={user.phoneNumber}
              onChange={(e: any) =>
                setUser({ ...user, phoneNumber: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            {databases && (
              <Select
                label='Default Database'
                name='defaultDatabase'
                icon='database'
                options={databases}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                defaultValue={{ label: "Select Database", value: 0 }}
                onChange={(value: any) =>
                  setUser({ ...user, defaultDatabase: value })
                }
                value={databases?.find(
                  (o) => o.id === user.defaultDatabase?.id
                )}
              />
            )}
          </div>
          <div className='col-6'>
            <DatabaseSelect
              label='Accessible Databases'
              name='databases'
              icon='database'
              isMulti={true}
              databases={databases}
              onChange={(value: any) => setUser({ ...user, databases: value })}
              value={user.databases}
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <InputFile
              id='avatar'
              label='Avatar'
              name='avatar'
              icon='file'
              onInput={(e: any) => {
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  if (reader.result) {
                    setUser({
                      ...user,
                      avatar: (reader.result as string).split(",")[1],
                    });
                  }
                };
                reader.onerror = function () {
                  console.log(reader.error);
                };
              }}
            />
          </div>
          <div className='col-6'>
            <Switch
              label='Active/Passive'
              id='isActive'
              name='isActive'
              value={user.isActive}
              setValue={() => setUser({ ...user, isActive: !user.isActive })}
            />
          </div>
        </div>
      </div>
      <br />
      <div className='ml-2 mr-2 clearfix'>
        <div style={{ textAlign: "center", display: "flex" }}>
          <hr style={{ width: "100%" }} />
          <p className='with-border' style={{ width: "30%" }}>
            Mikro Settings
          </p>
          <hr style={{ width: "100%" }} />
        </div>
        <div className='m-2'>
          <Select
            id='personelCode'
            label='Personel Kodu'
            placeholder='Enter personnel coe'
            name='personelCode'
            icon='people-group'
            type='text'
            options={personeller}
            getOptionLabel={(o: Personeller) => o.cariPerAdi}
            getOptionValue={(o: Personeller) => o.cariPerKod}
            value={personeller.find((o) => o.cariPerKod === user.personelCode)}
            onChange={(value: Personeller) =>
              setUser({ ...user, personelCode: value.cariPerKod })
            }
          />
        </div>
        <div className='m-2'>
          <div className='row'>
            <div className='col-6'>
              <Select
                id='sectorlar'
                label='Sektorlar'
                placeholder='Enter sector'
                name='sectorlar'
                icon='vector-square'
                type='text'
                isMulti={true}
                options={sectorlar}
                getOptionLabel={(o: Sektorlar) => o.sktrIsmi}
                getOptionValue={(o: Sektorlar) => o.sktrKod}
                value={user.mikroParams?.sectorlar?.split(",").map((sektor) => {
                  return sectorlar.find(
                    (s: Sektorlar) => s.sktrKod.toLowerCase() === sektor
                  ) as Sektorlar;
                })}
                onChange={(value: Sektorlar[]) =>
                  setUser({
                    ...user,
                    mikroParams: {
                      ...user.mikroParams,
                      sectorlar: value
                        .map((v) => v.sktrKod.toLowerCase())
                        .join(","),
                    },
                  })
                }
              />
            </div>
            <div className='col-6'>
              <Select
                id='depolar'
                label='Depolar'
                placeholder='Enter warehouse'
                name='depolar'
                icon='warehouse'
                type='text'
                isMulti={true}
                options={depolar}
                getOptionLabel={(o: Depolar) => o.depAdi}
                getOptionValue={(o: Depolar) => o.depNo}
                value={user.mikroParams?.depolar?.split(",").map((dep) => {
                  return depolar.find(
                    (s: Depolar) => s.depNo === +dep
                  ) as Depolar;
                })}
                onChange={(value: Depolar[]) =>
                  setUser({
                    ...user,
                    mikroParams: {
                      ...user.mikroParams,
                      depolar: value.map((v) => v.depNo).join(","),
                    },
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <Select
                id='qruplar'
                label='Qruplar'
                placeholder='Enter group'
                name='qruplar'
                icon='layer-group'
                type='text'
                isMulti={true}
                options={qruplar}
                getOptionLabel={(o: Qruplar) => o.crgIsim}
                getOptionValue={(o: Qruplar) => o.crgKod}
                value={user.mikroParams?.qruplar?.split(",").map((qr) => {
                  return qruplar.find(
                    (s: Qruplar) => s.crgKod.toLowerCase() === qr
                  ) as Qruplar;
                })}
                onChange={(value: Qruplar[]) =>
                  setUser({
                    ...user,
                    mikroParams: {
                      ...user.mikroParams,
                      qruplar: value
                        .map((v) => v.crgKod.toLowerCase())
                        .join(","),
                    },
                  })
                }
              />
            </div>
            <div className='col-6'>
              <Select
                id='srmMerkezleri'
                label='Sorumluluk Merkezleri'
                placeholder='Enter shops'
                name='srmMerkezleri'
                icon='shop'
                type='text'
                isMulti={true}
                options={merkezler}
                getOptionLabel={(o: Merkezler) => o.somIsim}
                getOptionValue={(o: Merkezler) => o.somKod}
                value={user.mikroParams?.srmMerkezleri
                  ?.split(",")
                  .map((srm) => {
                    return merkezler.find(
                      (s: Merkezler) => s.somKod.toLowerCase() === srm
                    ) as Merkezler;
                  })}
                onChange={(value: Merkezler[]) =>
                  setUser({
                    ...user,
                    mikroParams: {
                      ...user.mikroParams,
                      srmMerkezleri: value
                        .map((v) => v.somKod.toLowerCase())
                        .join(","),
                    },
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <Select
                id='bolgeler'
                label='Bolgeler'
                placeholder='Enter regions'
                name='bolgeler'
                icon='object-group'
                type='text'
                isMulti={true}
                options={bolgeler}
                getOptionLabel={(o: Bolgeler) => o.bolIsmi}
                getOptionValue={(o: Bolgeler) => o.bolKod}
                value={user.mikroParams?.bolgeler?.split(",").map((bol) => {
                  return bolgeler.find(
                    (s: Bolgeler) => s.bolKod.toLowerCase() === bol
                  ) as Bolgeler;
                })}
                onChange={(value: Bolgeler[]) =>
                  setUser({
                    ...user,
                    mikroParams: {
                      ...user.mikroParams,
                      bolgeler: value
                        .map((v) => v.bolKod.toLowerCase())
                        .join(","),
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
