import { faWindowMaximize as faWindowMax } from "@fortawesome/free-regular-svg-icons";
import {
  faPlus,
  faSyncAlt,
  faTimes,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

type CardType = {
  title: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
  onAdd?: () => void;
  onReload?: () => void;
  className?: string;
  isLoading?: boolean;
};

export default function CollapsibleCard({
  title,
  children,
  footer,
  onClose,
  onAdd,
  onReload,
  className,
  isLoading,
}: CardType) {
  const { darkMode } = useSelector((state: any) => state.toggle);
  const [collapse, setCollapse] = React.useState(true);
  return (
    <div className={className}>
      <div className='card'>
        <div className='card-header border-transparent'>
          <h3 className='card-title'>{title}</h3>
          <div className='card-tools'>
            {onAdd && (
              <button
                type='button'
                className='btn btn-tool'
                onClick={onAdd}
                data-tooltip-id='generalTooltip'
                data-tooltip-content='Add new'
                data-tooltip-place='left'
              >
                <FontAwesomeIcon icon={faPlus} size='lg' color='#22C55E' />
              </button>
            )}
            {onReload && (
              <button type='button' className='btn btn-tool' onClick={onReload}>
                <FontAwesomeIcon icon={faSyncAlt} size='lg' />
              </button>
            )}
            <button
              type='button'
              className='btn btn-tool'
              onClick={() => setCollapse(!collapse)}
              onDoubleClick={() => {}}
            >
              {collapse ? (
                <FontAwesomeIcon icon={faWindowMax} size='1x' />
              ) : (
                <FontAwesomeIcon icon={faWindowMaximize} size='1x' />
              )}
            </button>
            <button type='button' className='btn btn-tool' onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} size='lg' />
            </button>
          </div>
        </div>
        {/* <!-- /.card-header --> */}
        <AnimatePresence initial={false}>
          {collapse && (
            <motion.div
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div
                className='card-body p-0'
                //style={{ display: collapse ? "block" : "none" }}
              >
                {children}
              </div>
              {/* <!-- /.card-body --> */}
              <div
                className='card-footer clearfix'
                //style={{ display: collapse ? "block" : "none" }}
              >
                {footer}
              </div>

              {/* <!-- /.card-footer --> */}
            </motion.div>
          )}
        </AnimatePresence>
        {isLoading && (
          <div className={"overlay " + (darkMode ? "dark" : "")}>
            <FontAwesomeIcon icon={faSyncAlt} size={"3x"} spin />
          </div>
        )}
      </div>
    </div>
  );
}
