import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";

type InputProps = {
  name?: string;
  id?: string;
  databases: Database[];
  label: string;
  icon?: IconName;
  disabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  [x: string]: any;
};

export default function DatabaseSelect(input: InputProps) {
  const { darkMode } = useSelector((state: any) => state.toggle);
  const { ...rest } = input;
  const icon: IconName = input.icon ? input.icon : "circle";

  return (
    <div className='form-group'>
      <label htmlFor={input.id}>{input.label}</label>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            <FontAwesomeIcon icon={["fas", icon]} />
          </span>
        </div>
        <ReactSelect
          isDisabled={input.disabled}
          isLoading={input.isLoading}
          isClearable={input.isClearable}
          isRtl={false}
          isSearchable={input.isSearchable}
          isMulti={input.isMulti}
          name={input.name}
          id={input.id}
          options={input.databases}
          getOptionValue={(option: any) => option.id?.toString()}
          getOptionLabel={(option: any) => option.name}
          value={input.databases?.find((option) => option.id === rest.value)}
          styles={{
            control: (base) => ({
              ...base,
              borderColor: darkMode ? "#565e65" : "#e1e5e8",
              backgroundColor: darkMode ? "#343a40" : "#fff",
              color: darkMode ? "white" : "black",
              borderRadius: 0,
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: darkMode ? "#343a40" : "#fff",
            }),
            menuList: (base) => ({
              ...base,
              backgroundColor: darkMode ? "#343a40" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: darkMode ? "white" : "black",
            }),
            option: (base) => ({
              ...base,
              color: darkMode ? "white" : "black",
              backgroundColor: darkMode ? "#343a40" : "#fff",
              ":hover": {
                color: darkMode ? "white" : "black",
                backgroundColor: darkMode ? "#565e65" : "#e1e5e8",
              },
            }),
            clearIndicator(base, props) {
              return {
                ...base,
                color: darkMode ? "black" : "white",
              };
            },
          }}
          {...rest}
        />
      </div>
    </div>
  );
}
