import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function DualList({
  options,
  selected,
  setSelected,
  onRemove,
  getOptionLabel,
  getOptionValue,
  onSave,
  label,
}: any) {
  const [selectedOption, setSelectedOption] = React.useState<any[]>([]);
  return (
    <div className='form-group'>
      <label>{label}</label>
      <div className='input-group '>
        <select
          className='form-inline'
          multiple={true}
          name='available'
          style={{ height: "200px", width: "300px" }}
        >
          {options.map((option: any, index: number) => (
            <option
              key={index}
              value={option[getOptionValue]}
              onClick={() => {
                setSelectedOption(option);
              }}
            >
              {option[getOptionLabel]}
            </option>
          ))}
        </select>
        <div className=''>
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => setSelected(selectedOption)}
          >
            <FontAwesomeIcon icon={["fas", "angles-down"]} />
          </button>
        </div>
        <select
          className='form-inline'
          multiple={true}
          name='selected'
          style={{ height: "200px", width: "300px" }}
        >
          {selected.map((option: any, index: number) => (
            <option
              key={index}
              value={option[getOptionValue]}
              onClick={() => onRemove(option)}
            >
              {option[getOptionLabel]}
            </option>
          ))}
        </select>

        {onSave && (
          <div className='input-group-append'>
            <button className='btn btn-primary' type='button' onClick={onSave}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
