import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type InputFileProps = {
  name?: string;
  id?: string;
  placeholder?: string;
  label: string;
  icon?: IconName;
  [x: string]: any;
};

export default function InputFile(props: InputFileProps) {
  const { id, name, placeholder, label, icon, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <div className='input-group'>
        {icon && (
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={["fas", icon]} />
            </span>
          </div>
        )}
        <input
          type='file'
          accept='image/*'
          name={name}
          className='form-control'
          id={id}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
}
