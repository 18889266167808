import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type SwitchProps = {
  name?: string;
  id?: string;
  label: string;
  icon?: IconName;
  value?: boolean;
  setValue?: any;
  [x: string]: any;
};

export default function Switch(props: SwitchProps) {
  const { label, icon, id, name, value, setValue, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <div className='input-group'>
        {icon && (
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={["fas", icon]} />
            </span>
          </div>
        )}
        <div
          className={
            "bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-focused bootstrap-switch-animate" +
            (value ? " bootstrap-switch-on" : " bootstrap-switch-off")
          }
          style={{ width: "86px" }}
          onClick={setValue}
        >
          <div
            className='bootstrap-switch-container'
            style={{ width: "126px", marginLeft: (value ? 42 : 0) + "px" }}
          >
            <span
              className={
                "bootstrap-switch-handle-on " +
                (value ? "bootstrap-switch-success" : "bootstrap-switch-danger")
              }
              style={{ width: "42px" }}
            >
              {value ? "ON" : "OFF"}
            </span>
            <input
              type='checkbox'
              name={name}
              id={id}
              checked={value}
              onChange={setValue}
              {...rest}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
