import { IconName, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { authSelector } from "../../store/slices/AuthSlice";
import { useEffect, useState } from "react";
import { toggleSelector } from "../../store/slices/ToggleSlice";
import { RunInfoCard } from "../../services/DashboardService";

export default function InfoCard({ id }: { id: any }) {
  const { user } = useAppSelector(authSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { database, darkMode } = useAppSelector(toggleSelector);
  const [db, setDb] = useState<Database>(user?.defaultDatabase as Database);
  const [data, setData] = useState<InfoCard>({} as InfoCard);

  const dispatch = useAppDispatch();

  const loadData = () => {
    setIsLoading(true);
    if (id && id === 0) {
      console.log("Info ID is 0");
      setError("Info ID is 0");
      setIsLoading(false);
      return;
    }
    if (db && db.id === 0) {
      console.log("Database ID is 0");
      setError("Database ID is 0");
      setIsLoading(false);
      return;
    }
    if (id && db && db.id) {
      RunInfoCard(id, db.id)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
            setIsLoading(false);
          } else {
            setError(response.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setDb(user?.defaultDatabase as Database);
  }, [user]);

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <div className='col-12 col-sm-6 col-md-3'>
      <div className='info-box'>
        <span className={`info-box-icon ${data.color} elevation-1`}>
          {data.icon && (
            <FontAwesomeIcon icon={["fas", data.icon as IconName]} />
          )}
        </span>

        <div className='info-box-content'>
          <span className='info-box-text'>{data.title}</span>
          <span className='info-box-number'>{data.value}</span>
        </div>
        {isLoading && (
          <div className={"overlay " + (darkMode ? "dark" : "")}>
            <FontAwesomeIcon icon={faSyncAlt} size={"3x"} spin />
          </div>
        )}
      </div>
    </div>
  );
}
