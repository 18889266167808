import { useEffect, useState } from "react";
import { useAppSelector } from "../../utils/Hooks";
import { toggleSelector } from "../../store/slices/ToggleSlice";
import {
  GetBolgeler,
  GetDepolar,
  GetPersoneller,
  GetQruplar,
  GetSektorlar,
  GetSrmMerkezler,
} from "../../services/MikroService";
import Select from "../Inputs/Select";
import Input from "../Inputs/Input";
import { Capitalize } from "../../utils/extensions";

export default function CustomParamInput({
  param: param,
  onChange: onChange,
}: {
  param: ReportParam;
  onChange: any;
}) {
  const [sectorlar, setSectorlar] = useState<Sektorlar[]>([]);
  const [qruplar, setQruplar] = useState<Qruplar[]>([]);
  const [bolgeler, setBolgeler] = useState<Bolgeler[]>([]);
  const [personeller, setPersoneller] = useState<Personeller[]>([]);
  const [depolar, setDepolar] = useState<Depolar[]>([]);
  const [merkezler, setMerkezler] = useState<Merkezler[]>([]);
  const { database } = useAppSelector(toggleSelector);

  const loadSelectBoxes = (dbId: number) => {
    GetDepolar({ dbId: dbId }).then((response) => {
      setDepolar(response.data);
    });

    GetSrmMerkezler({ dbId: dbId }).then((response) => {
      setMerkezler(response.data);
    });

    GetBolgeler({ dbId: dbId }).then((response) => {
      setBolgeler(response.data);
    });

    GetQruplar({ dbId: dbId }).then((response) => {
      setQruplar(response.data);
    });

    GetPersoneller({ dbId: dbId }).then((response) => {
      setPersoneller(response.data);
    });

    GetSektorlar({ dbId: dbId }).then((response) => {
      setSectorlar(response.data);
    });
  };

  useEffect(() => {
    if (database) {
      loadSelectBoxes(database.id ?? 0);
    }
  }, []);
  return param.type === "+sectorlar" ? (
    <Select
      id={param.key}
      label='Sektorlar'
      placeholder='Enter sector'
      name='sectorlar'
      icon='vector-square'
      type='text'
      isMulti={true}
      options={sectorlar}
      getOptionLabel={(o: Sektorlar) => o.sktrIsmi}
      getOptionValue={(o: Sektorlar) => o.sktrKod}
      value={param.value?.split(",").map((sektor) => {
        return sectorlar.find(
          (s: Sektorlar) => s.sktrKod.toLowerCase() === sektor
        ) as Sektorlar;
      })}
      onChange={(value: Sektorlar[]) =>
        onChange(param, value.map((v) => v.sktrKod.toLowerCase()).join(","))
      }
    />
  ) : param.type === "+depolar" ? (
    <Select
      id={param.key}
      label='Depolar'
      placeholder='Enter warehouse'
      name='depolar'
      icon='warehouse'
      type='text'
      isMulti={true}
      options={depolar}
      getOptionLabel={(o: Depolar) => o.depAdi}
      getOptionValue={(o: Depolar) => o.depNo}
      value={param.value?.split(",").map((sektor) => {
        return depolar.find((s: Depolar) => s.depNo === +sektor) as Depolar;
      })}
      onChange={(value: Depolar[]) =>
        onChange(param, value.map((v) => v.depNo).join(","))
      }
    />
  ) : param.type === "+qruplar" ? (
    <Select
      id={param.key}
      label='Qruplar'
      placeholder='Enter group'
      name='qruplar'
      icon='layer-group'
      type='text'
      isMulti={true}
      options={qruplar}
      getOptionLabel={(o: Qruplar) => o.crgIsim}
      getOptionValue={(o: Qruplar) => o.crgKod}
      value={param.value?.split(",").map((sektor) => {
        return qruplar.find(
          (s: Qruplar) => s.crgKod.toLowerCase() === sektor
        ) as Qruplar;
      })}
      onChange={(value: Qruplar[]) =>
        onChange(param, value.map((v) => v.crgKod.toLowerCase()).join(","))
      }
    />
  ) : param.type === "+srmMerkezleri" ? (
    <Select
      id={param.key}
      label='Sorumluluk Merkezleri'
      placeholder='Enter shops'
      name='srmMerkezleri'
      icon='shop'
      type='text'
      isMulti={true}
      options={merkezler}
      getOptionLabel={(o: Merkezler) => o.somIsim}
      getOptionValue={(o: Merkezler) => o.somKod}
      value={param.value?.split(",").map((sektor) => {
        return merkezler.find(
          (s: Merkezler) => s.somKod.toLowerCase() === sektor
        ) as Merkezler;
      })}
      onChange={(value: Merkezler[]) =>
        onChange(param, value.map((v) => v.somKod.toLowerCase()).join(","))
      }
    />
  ) : param.type === "+bolgeler" ? (
    <Select
      id={param.key}
      label='Bolgeler'
      placeholder='Enter regions'
      name='bolgeler'
      icon='object-group'
      type='text'
      isMulti={true}
      options={bolgeler}
      getOptionLabel={(o: Bolgeler) => o.bolIsmi}
      getOptionValue={(o: Bolgeler) => o.bolKod}
      value={param.value?.split(",").map((sektor) => {
        return bolgeler.find(
          (s: Bolgeler) => s.bolKod.toLowerCase() === sektor
        ) as Bolgeler;
      })}
      onChange={(value: Bolgeler[]) =>
        onChange(param, value.map((v) => v.bolKod.toLowerCase()).join(","))
      }
    />
  ) : param.type === "+personeller" ? (
    <Select
      id={param.key}
      label='Personeller'
      placeholder='Enter personeller'
      name='personeller'
      icon='people-group'
      type='text'
      options={personeller}
      isMulti={true}
      getOptionLabel={(o: Personeller) => o.cariPerAdi}
      getOptionValue={(o: Personeller) => o.cariPerKod}
      value={param.value?.split(",").map((sektor) => {
        return personeller.find(
          (s: Personeller) => s.cariPerKod.toLowerCase() === sektor
        ) as Personeller;
      })}
      onChange={(value: Personeller[]) =>
        onChange(param, value.map((v) => v.cariPerKod.toLowerCase()).join(","))
      }
    />
  ) : (
    <div className='col-6' key={param.key}>
      <Input
        id={param.key}
        label={Capitalize(param.key)}
        placeholder={"Enter " + param.key}
        name={param.key}
        icon='font'
        type='text'
        value={param.value}
        onChange={(e: any) => onChange(param, e.target.value)}
      />
    </div>
  );
}
