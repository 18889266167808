import React, { useEffect } from "react";
import CollapsibleCard from "../../components/CollapsibleCard";
import TableAvatar from "../../components/TableAvatar/TableAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesDown,
  faAnglesUp,
  faEdit,
  faEye,
  faGripLines,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import "@sweetalert2/themes/dark/dark.css";
import { DeleteTask, GetTasks } from "../../services/TaskService";
import Moment from "moment";
import Input from "../../components/Inputs/Input";
import HasAccess from "../auth/protected-components";

export default function TaskList() {
  const navigate = useNavigate();
  const initTasks: Task[] = [];

  const [tasks, setTasks] = React.useState<Task[]>(initTasks);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");

  const MySwal = withReactContent(Swal);

  const deleteHandler = (id: number) => {
    if (id === 0) {
      return;
    }
    MySwal.fire({
      title: "Silmək istədiyinizdən əminsinizmi?",
      showCancelButton: true,
      cancelButtonText: "Xeyr",
      cancelButtonColor: "#f93154",
      confirmButtonColor: "#00b74a",
      confirmButtonText: "Bəli",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteTask(id)
          .then((res) => {
            console.log(res);
            if (res.status === 204) {
              toast.success(res.message);
              getData();
            } else {
              toast.error(res.message);
            }
          })
          .catch((e) => toast.error(e.message));
      }
    });
  };

  const getData = (s?: string) => {
    setLoading(true);
    GetTasks({ q: s }).then((res) => {
      setTasks(res.data);
      setLoading(false);
    });
  };

  const addNew = () => {
    navigate("/tasks/add");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <CollapsibleCard
      title='Tasks'
      onAdd={addNew}
      onReload={() => getData()}
      isLoading={loading}
    >
      <div className='row ml-3'>
        <Input
          label='Search'
          icon='search'
          placeholder='Search in tasks'
          infotext='Enter task name to search'
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          btnClick={() => getData(search)}
          btncolor='btn-outline-secondary'
          btnname='Search'
        />
      </div>
      {!tasks ||
        (tasks.length === 0 && (
          <h5 className='text-center'>There is no data.</h5>
        ))}
      {tasks && tasks.length > 0 && (
        <table className='table table-responsive table-striped projects'>
          <thead>
            <tr>
              <th style={{ width: "1%" }}>#</th>
              <th style={{ width: "20%" }}>Task Name</th>
              <th style={{ width: "30%" }}>Members</th>
              <th style={{ width: "8%" }} className='text-center'>
                Priority
              </th>
              <th style={{ width: "8%" }} className='text-center'>
                Status
              </th>
              <th style={{ width: "20%" }}></th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((t, i) => (
              <tr key={t.id}>
                <td>{t.id}</td>
                <td>
                  <a>{t.title}</a>
                  <br />
                  <small>
                    Created {Moment(t.createdAt).format("DD.MM.yyyy HH:mm:ss")}
                  </small>
                </td>
                <td>
                  <ul className='list-inline'>
                    {t.members &&
                      t.members.map((m) => (
                        <li className='list-inline-item' key={m.id}>
                          <TableAvatar user={m} />
                        </li>
                      ))}
                  </ul>
                </td>
                <td className='project-state'>
                  <span
                    className={
                      "no-select badge " +
                      (t.priority.value === "low"
                        ? "badge-warning"
                        : t.priority.value === "normal"
                        ? "badge-success"
                        : "badge-danger")
                    }
                  >
                    {t.priority.value === "low" && (
                      <FontAwesomeIcon icon={faAnglesDown} />
                    )}
                    {t.priority.value === "normal" && (
                      <FontAwesomeIcon icon={faGripLines} />
                    )}
                    {t.priority.value === "high" && (
                      <FontAwesomeIcon icon={faAnglesUp} />
                    )}
                    {" " + t.priority.label}
                  </span>
                </td>
                <td className='project-state'>
                  <span
                    className={
                      "no-select badge " +
                      (t.status.value === "open"
                        ? "badge-success"
                        : "badge-danger")
                    }
                  >
                    {t.status.value}
                  </span>
                </td>
                <td className='project-actions text-right'>
                  <NavLink
                    className='btn btn-primary btn-sm ml-1 mb-1'
                    to={"/tasks/" + t.id}
                  >
                    <FontAwesomeIcon icon={faEye} className='mr-1' />
                    View
                  </NavLink>
                  <HasAccess roles={["admin"]}>
                    <NavLink
                      className='btn btn-info btn-sm ml-1 mb-1'
                      to={"/tasks/" + t.id + "/edit"}
                    >
                      <FontAwesomeIcon icon={faEdit} className='mr-1' />
                      Edit
                    </NavLink>
                  </HasAccess>
                  <HasAccess roles={["admin"]}>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm ml-1 mb-1'
                      onClick={() => deleteHandler(t.id ?? 0)}
                    >
                      <FontAwesomeIcon icon={faTrash} className='mr-1' />
                      Delete
                    </button>
                  </HasAccess>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </CollapsibleCard>
  );
}
