import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../store/slices/ToggleSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function AppName() {
  const dispatch = useDispatch<any>();
  const { sideBarOpen } = useSelector((state: any) => state.toggle);
  const handleSideBar = () => {
    dispatch(toggleSidebar(sideBarOpen));
  };
  return (
    <div className='brand-link'>
      <img
        src='/img/logo.png'
        alt='logo'
        className='brand-image img-circle elevation-3'
        style={{ opacity: 0.8 }}
      />
      <span className='brand-text font-weight-light'>CAHAN</span>
      {sideBarOpen && (
        <span className='float-right' onClick={handleSideBar}>
          <FontAwesomeIcon icon={faBars} />
        </span>
      )}
    </div>
  );
}
