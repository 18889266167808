import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useAppSelector } from "../../../utils/Hooks";
import { toggleSelector } from "../../../store/slices/ToggleSlice";
import "./style.css";

type InputProps = {
  name?: string;
  id?: string;
  placeholder?: string;
  value?: any;
  label: string;
  icon?: IconName;
  language?: string;
  infotext?: string;
  [x: string]: any;
};

export default function CustomCodeEditor(input: InputProps) {
  const { ...rest } = input;
  const [value, setValue] = useState(input.value);
  const icon: IconName = input.icon ? input.icon : "circle";
  const { darkMode } = useAppSelector(toggleSelector);
  return (
    <>
      <div className='form-group'>
        <label htmlFor={input.id}>
          {input.label}{" "}
          {input.infotext && <small>{"( " + input.infotext + " )"}</small>}
        </label>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={["fas", icon]} />
            </span>
          </div>
          <CodeEditor
            language={input.language}
            padding={10}
            style={{
              height: "150px",
              overflow: "auto",
            }}
            data-color-mode={darkMode ? "dark" : "light"}
            name={input.name}
            className='form-control'
            id={input.id}
            placeholder={input.placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            {...rest}
          />
        </div>
      </div>
    </>
  );
}
