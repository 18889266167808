import React from "react";
import { Breadcrumbs } from "../Breadcrumbs";

export default function ContentHeader() {
  return (
    // <!-- Content Header (Page header) -->
    <div className='content-header'>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className='col-sm-6'>
            <h1 className='m-0'>Dashboard</h1>
          </div>
          <div className='col-sm-6'>
            <Breadcrumbs />
          </div>
        </div>
      </div>
    </div>
    // <!-- /.content-header -->
  );
}
