import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import {
  dashboardSelector,
  setAvailableCharts,
} from "../../store/slices/DashboardSlice";
import { GetCharts } from "../../services/DashboardService";
import Chart from "../ChartJS/Chart";

export default function ChartContainer() {
  const { charts, infoCards, maps } = useAppSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const [grouppedCards, setGrouppedCards] = useState<any[]>([]);

  const loadCharts = async () => {
    try {
      await GetCharts().then((res) => {
        if (res.status === 200) {
          dispatch(setAvailableCharts(res.data));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const initLayout = () => {
    if (charts && charts.length > 0) {
      const grp = [];
      for (let i = 0; i < charts.length; i += 2) {
        if (charts[i].show) {
          grp.push(charts.slice(i, i + 2));
        }
      }
      setGrouppedCards(grp);
    }
  };

  useEffect(() => {
    initLayout();
  }, [charts]);

  useEffect(() => {
    loadCharts();
  }, []);
  return (
    <>
      {grouppedCards
        .sort((a, b) => a[0].order - b[0].order)
        .map((group, index) => (
          <div className='row' key={index}>
            {group.map((chart: Chart, idx: number) => (
              <Chart
                key={idx}
                chartId={chart.id}
                chartType={chart.chartType}
                className={
                  group.length === 1
                    ? "col-12 col-sm-12 col-md-12 col-lg-12"
                    : "col-12 col-sm-12 col-md-6 col-lg-6"
                }
              />
            ))}
          </div>
        ))}
    </>
  );
}
