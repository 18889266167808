import { MIKRO_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetSektorlar(qparams?: any) {
  return axios
    .get(MIKRO_URL + "sektorlar/", { params: { ...qparams } })
    .then((response): ResponseModel<Sektorlar[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Sektorlar[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetDepolar(qparams?: any) {
  return axios
    .get(MIKRO_URL + "depolar/", { params: { ...qparams } })
    .then((response): ResponseModel<Depolar[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Depolar[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetSrmMerkezler(qparams?: any) {
  return axios
    .get(MIKRO_URL + "srmmerkezleri/", {
      params: { ...qparams },
    })
    .then((response): ResponseModel<Merkezler[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Merkezler[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetQruplar(qparams?: any) {
  return axios
    .get(MIKRO_URL + "qruplar/", { params: { ...qparams } })
    .then((response): ResponseModel<Qruplar[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Qruplar[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetBolgeler(qparams?: any) {
  return axios
    .get(MIKRO_URL + "bolgeler/", { params: { ...qparams } })
    .then((response): ResponseModel<Bolgeler[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Bolgeler[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetPersoneller(qparams?: any) {
  return axios
    .get(MIKRO_URL + "personeller/", { params: { ...qparams } })
    .then((response): ResponseModel<Personeller[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Personeller[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
