import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useRouteError } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ContentHeader from "../components/ContentHeader";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

export default function ErrorPage() {
  const error: any = useRouteError();
  const { sideBarOpen, darkMode } = useSelector((state: any) => state.toggle);

  return (
    <div
      className={
        (darkMode ? " dark-mode " : " ") +
        " sidebar-mini  " +
        (sideBarOpen ? " sidebar-open " : " sidebar-close sidebar-collapse")
      }
    >
      <div className='wrapper'>
        <Navbar />
        <Sidebar />
        <div className='content-wrapper'>
          <ContentHeader />
          <section className='content'>
            <div className='error-page'>
              <h2 className='headline text-warning'> 404</h2>

              <div className='error-content'>
                <h3>
                  <i className='fas fa-exclamation-triangle text-warning'></i>{" "}
                  Oops! Page not found.
                </h3>

                <p>
                  {error.statusText || error.message} <br />
                  We could not find the page you were looking for. Meanwhile,
                  you may <Link to='/'>return to dashboard</Link> or try using
                  the search form.
                </p>

                <form className='search-form'>
                  <div className='input-group'>
                    <input
                      type='text'
                      name='search'
                      className='form-control'
                      placeholder='Search'
                    />

                    <div className='input-group-append'>
                      <button
                        type='submit'
                        name='submit'
                        className='btn btn-warning'
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}
