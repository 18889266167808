import React, { useEffect } from "react";
import Switch from "../../../components/Inputs/Switch";
import InputFile from "../../../components/Inputs/InputFile";
import Input from "../../../components/Inputs/Input";
import Select from "../../../components/Inputs/Select";
import CollapsibleCard from "../../../components/CollapsibleCard";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { GetRoles } from "../../../services/RoleService";
import { InsertUser } from "../../../services/UserService";
import { GetDatabases } from "../../../services/DatabaseService";
import DatabaseSelect from "../../../components/Inputs/DatabaseSelect";

export default function UserAdd() {
  const navigate = useNavigate();
  const userData: User = {
    isActive: false,
    displayName: "",
    username: "",
    role: {} as Role,
    email: "",
    phoneNumber: "",
    avatar: "",
    password: "",
    defaultDatabase: {} as Database,
    databases: [],
  };
  const [user, setUser] = React.useState<User>(userData);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [databases, setDatabases] = React.useState<Database[]>([]);
  const [loading, setLoading] = React.useState(false);

  const addUser = (data: User) => {
    setLoading(true);
    InsertUser(data).then((response) => {
      if (response.status === 200) {
        toast.success(`${data.username} əlavə edildi.`);
        navigate("/users");
      }
    });
    setLoading(false);
  };

  const _footerContent = (
    <div className='d-flex justify-content-between'>
      <button
        className='btn btn-outline-warning'
        onClick={() => navigate("/users")}
      >
        Cancel
      </button>
      <button className='btn btn-outline-success' onClick={() => addUser(user)}>
        Save
      </button>
    </div>
  );

  useEffect(() => {
    setLoading(true);
    GetRoles().then((response) => {
      setRoles(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    GetDatabases().then((response) => {
      setDatabases(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <CollapsibleCard
      title={"Add new user"}
      footer={_footerContent}
      isLoading={loading}
    >
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='username'
              label='Username'
              placeholder='Enter username'
              name='username'
              icon='user'
              type='text'
              value={user.username}
              onChange={(e: any) =>
                setUser({ ...user, username: e.target.value })
              }
            />
          </div>
          <div className='col-6'>
            <Input
              id='password'
              label='Password'
              placeholder=''
              name='password'
              icon='lock'
              type='password'
              value={user.password}
              onChange={(e: any) =>
                setUser({ ...user, password: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='displayName'
              label='Display Name'
              placeholder='Enter Display Name'
              name='displayName'
              icon='pencil-alt'
              type='text'
              value={user.displayName}
              onChange={(e: any) =>
                setUser({ ...user, displayName: e.target.value })
              }
            />
          </div>
          <div className='col-6'>
            <Select
              label='Role'
              name='role'
              icon='check-double'
              options={roles}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              onChange={(value: Role) => setUser({ ...user, role: value })}
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <Input
              id='email'
              label='Email'
              placeholder='Enter email'
              name='email'
              icon='envelope'
              type='text'
              value={user.email}
              onChange={(e: any) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className='col-6'>
            <Input
              id='phoneNumber'
              label='Phone Number'
              placeholder='Enter phone number'
              name='phoneNumber'
              icon='phone'
              type='text'
              value={user.phoneNumber}
              onChange={(e: any) =>
                setUser({ ...user, phoneNumber: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            {databases && (
              <Select
                label='Default Database'
                name='defaultDatabase'
                icon='database'
                options={databases}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                defaultValue={{ label: "Select Database", value: 0 }}
                onChange={(value: any) =>
                  setUser({ ...user, defaultDatabase: value })
                }
                value={databases?.find(
                  (o) => o.id === user.defaultDatabase?.id
                )}
              />
            )}
          </div>
        </div>
      </div>
      <div className='m-2'>
        <div className='row'>
          <div className='col-6'>
            <InputFile
              id='avatar'
              label='Avatar'
              name='avatar'
              icon='file'
              onInput={(e: any) =>
                setUser({
                  ...user,
                  avatar: URL.createObjectURL(e.target.files[0]),
                })
              }
            />
          </div>
          <div className='col-6'>
            <Switch
              label='Active/Passive'
              id='isActive'
              name='isActive'
              value={user.isActive}
              setValue={() => setUser({ ...user, isActive: !user.isActive })}
            />
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
