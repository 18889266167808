import { Link, createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error-page";
import Home from "../pages/home";
import Login from "../pages/auth/login-page";
import ProtectedRoutes from "../pages/auth/protected-routes";
import Users from "../pages/admin/users/users";
import Settings from "../pages/admin/settings";
import Tasks from "../pages/tasks/tasks";
import TaskList from "../pages/tasks/task-list";
import TaskEdit from "../pages/tasks/task-edit";
import TaskView from "../pages/tasks/task-view";
import TaskAdd from "../pages/tasks/task-add";
import UserList from "../pages/admin/users/user-list";
import UserAdd from "../pages/admin/users/user-add";
import UserView from "../pages/admin/users/user-view";
import UserEdit from "../pages/admin/users/user-edit";
import ReportPage from "../pages/reports";
import Report from "../pages/reports/page";
import ReportList from "../pages/reports/report-list";
import ReportAdd from "../pages/reports/report-add";
import ReportEdit from "../pages/reports/report-edit";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => "Home",
    },
    children: [
      {
        path: "/",
        element: <Home />,
        handle: {
          crumb: () => <Link to={"/"}>Dashboard</Link>,
        },
      },
      {
        path: "/home",
        element: <Home />,
        handle: {
          crumb: () => <Link to={"/"}>Home</Link>,
        },
      },
      {
        path: "/reports",
        element: <ReportPage />,
        handle: {
          crumb: () => <Link to={"/reports/list"}>Reports</Link>,
        },
        children: [
          {
            path: "/reports/list",
            element: <ReportList />,
            handle: {
              crumb: () => <Link to={"/reports/list"}>Reports</Link>,
            },
          },
          {
            path: "/reports/add",
            element: <ReportAdd />,
            handle: {
              crumb: () => <Link to={"/reports/add"}>Report Add</Link>,
            },
          },
          {
            path: "/reports/:id/edit",
            element: <ReportEdit />,
            handle: {
              crumb: () => "Report Edit",
            },
          },
          {
            path: "/reports/:id",
            element: <Report />,
            handle: {
              crumb: () => <Link to={"/reports/list"}>Report</Link>,
            },
          },
        ],
      },
      {
        path: "/tasks",
        element: <Tasks />,
        handle: {
          crumb: () => <Link to={"/tasks"}>Tasks</Link>,
        },
        children: [
          {
            path: "/tasks",
            element: <TaskList />,
            handle: {
              crumb: () => <Link to={"/tasks"}>Task List</Link>,
            },
          },
          {
            path: "/tasks/add",
            element: <TaskAdd />,
            handle: {
              crumb: () => <Link to={"/tasks/add"}>Task Add</Link>,
            },
          },
          {
            path: "/tasks/:id",
            element: <TaskView />,
            handle: {
              crumb: () => <Link to={"/tasks"}>Task View</Link>,
            },
          },
          {
            path: "/tasks/:id/edit",
            element: <TaskEdit />,
            handle: {
              crumb: () => <Link to={"/tasks"}>Task Edit</Link>,
            },
          },
        ],
      },
      {
        path: "/users",
        element: <Users />,
        handle: {
          crumb: () => <Link to={"/users"}>Users</Link>,
        },
        children: [
          {
            path: "/users",
            element: <UserList />,
            handle: {
              crumb: () => <Link to={"/users"}>Task List</Link>,
            },
          },
          {
            path: "/users/add",
            element: <UserAdd />,
            handle: {
              crumb: () => <Link to={"/users/add"}>User Add</Link>,
            },
          },
          {
            path: "/users/:id",
            element: <UserView />,
            handle: {
              crumb: () => <Link to={"/users"}>User View</Link>,
            },
          },
          {
            path: "/users/:id/edit",
            element: <UserEdit />,
            handle: {
              crumb: () => <Link to={"/users"}>User Edit</Link>,
            },
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        handle: {
          crumb: () => <Link to={"/settings"}>Settings</Link>,
        },
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
