import { useAppDispatch, useAppSelector } from "../utils/Hooks";
import { dashboardSelector } from "../store/slices/DashboardSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authSelector } from "../store/slices/AuthSlice";
import { toggleDatabase } from "../store/slices/ToggleSlice";
import InfoCardContainer from "../components/Dashboard/InfoCardContainer";
import MapContainer from "../components/Dashboard/MapContainer";
import ChartContainer from "../components/Dashboard/ChartContainer";

export default function Home() {
  const { charts, infoCards, maps } = useAppSelector(dashboardSelector);
  const { user } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleDatabase(user?.defaultDatabase));
  }, [user]);

  return (
    <div className='container-fluid'>
      {charts &&
        charts.length == 0 &&
        infoCards &&
        infoCards.length == 0 &&
        maps &&
        maps.length == 0 && (
          <div className='row'>
            <div className='col-12'>
              <div className='text-center'>Dashboard is empty</div>
              <div className='text-center'>
                Add widgets from{" "}
                <Link to='/settings' className='text-warning'>
                  Settings
                </Link>
              </div>
            </div>
          </div>
        )}

      {/* <!-- Info boxes --> */}
      <InfoCardContainer />

      {/* Maps */}
      <MapContainer />

      {/* Charts */}
      <ChartContainer />
    </div>
  );
}
