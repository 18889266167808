import { USERS_URL, USER_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetUsers(qparams?: any) {
  return axios
    .get(USERS_URL, { params: { ...qparams } })
    .then((response): ResponseModel<User[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<User[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response.statusText,
      };
    });
}

export async function GetUser(id: number) {
  return axios
    .get(USER_URL + `${id}`)
    .then((response): ResponseModel<User> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateUser(id: any, user: User) {
  return axios
    .put(USER_URL + `${id}`, user)
    .then((response): ResponseModel<User> => {
      let user = response.data as User;
      return {
        data: response.data,
        status: response.status,
        message: `${user.username} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertUser(user: User) {
  return axios
    .post(USER_URL, user)
    .then((response): ResponseModel<User> => {
      let user = response.data as User;
      return {
        data: response.data,
        status: response.status,
        message: `${user.username} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteUser(id: number) {
  return axios
    .delete(USER_URL + `${id}`)
    .then((response): ResponseModel<User> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<User> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
