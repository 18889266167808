import {
  faArrowRightFromBracket,
  faBars,
  faMoon,
  faSearch,
  faSun,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleDarkMode,
  toggleSelector,
  toggleSidebar,
} from "../../store/slices/ToggleSlice";
import LanguageSelector from "../LanguageSelector";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
import DatabaseSelector from "../DatabaseSelector";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { authSelector, setUser } from "../../store/slices/AuthSlice";

export default function Navbar() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sideBarOpen, darkMode } = useAppSelector(toggleSelector);
  const { user } = useAppSelector(authSelector);
  const handleSideBar = () => {
    dispatch(toggleSidebar());
  };
  const handleDarkMode = () => {
    dispatch(toggleDarkMode(darkMode));
  };
  const handleLogOff = () => {
    setToken(null);
    dispatch(setUser(null));
    navigate("/login");
  };
  return (
    <nav
      className='main-header navbar navbar-expand navbar-dark'
      style={{ userSelect: "none" }}
    >
      {/* <!-- Left navbar links --> */}
      <ul className='navbar-nav'>
        <li className='nav-item'>
          {!sideBarOpen && (
            <div className='nav-link' role='button' onClick={handleSideBar}>
              <FontAwesomeIcon icon={faBars} />
            </div>
          )}
        </li>
      </ul>

      {/* <!-- Right navbar links --> */}
      <ul className='navbar-nav ml-auto'>
        {/* <!-- Navbar Search --> */}
        <li className='nav-item'>
          <a className='nav-link' href='#' role='button'>
            <FontAwesomeIcon icon={faSearch} />
          </a>
          <div className='navbar-search-block'>
            <form className='form-inline'>
              <div className='input-group input-group-sm'>
                <input
                  className='form-control form-control-navbar'
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                />
                <div className='input-group-append'>
                  <button className='btn btn-navbar' type='submit'>
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                  <button className='btn btn-navbar' type='button'>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        <li className='nav-item'>
          <DatabaseSelector />
        </li>
        <li className='nav-item'>
          <LanguageSelector />
        </li>
        <li className='nav-item'>
          <span className='nav-link' role='button' onClick={handleDarkMode}>
            {darkMode ? (
              <FontAwesomeIcon icon={faSun} />
            ) : (
              <FontAwesomeIcon icon={faMoon} />
            )}
          </span>
        </li>
        <li className='nav-item'>
          <span className='nav-link' role='button' onClick={handleLogOff}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </span>
        </li>
      </ul>
    </nav>
  );
}
