import React from "react";

export default function Footer() {
  return (
    // <!-- Main Footer -->
    <footer className='main-footer'>
      <strong>
        Copyright &copy; 2024 <a href='#'>CAHAN</a>.
      </strong>
      All rights reserved.
      <div className='float-right d-none d-sm-inline-block'>
        <b>Version</b> 1.0.0
      </div>
    </footer>
  );
}
