import React, { CSSProperties } from "react";

export default function TableAvatar({
  user,
  style,
}: {
  user: User;
  style?: CSSProperties;
}) {
  return (
    <img
      alt='Avatar'
      className='table-avatar'
      style={style}
      src={user.avatar ? user.avatar.toString() : "/img/avatar.png"}
      onMouseOver={() => {}}
      data-tooltip-id='generalTooltip'
      data-tooltip-content={user.displayName}
      data-tooltip-place='top'
    />
  );
}
