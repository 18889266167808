import AppName from "./AppName";
import UserPanel from "./UserPanel";
import SearchForm from "./SearchForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { authSelector } from "../../store/slices/AuthSlice";
import { GetReports } from "../../services/ReportService";
import {
  IconName,
  faAngleLeft,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import {
  dashboardSelector,
  setSidebarMenus,
} from "../../store/slices/DashboardSlice";
import HasAccess from "../../pages/auth/protected-components";

export default function Sidebar() {
  const [openMenu, setOpenMenu] = useState(false);
  const { user } = useAppSelector(authSelector);
  const { sidebarMenus } = useAppSelector(dashboardSelector);
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState<SidebarMenu[]>([]);

  const dispatch = useAppDispatch();

  const loadMenu = () => {
    setLoading(true);
    const menus: SidebarMenu[] = [];
    GetReports({ type: "report" })
      .then((res) => {
        res?.data &&
          res.data.forEach((report) => {
            const menu: SidebarMenu = {
              id: report.id,
              title: report.title,
              path: `/reports/${report.id}`,
              icon: report.icon ?? "folder",
              role: report.roles.split(","),
            };
            menus.push(menu);
          });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setMenus(menus);
        dispatch(setSidebarMenus(menus));
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMenu();
  }, [user]);

  return (
    <aside className='main-sidebar sidebar-dark-primary elevation-4'>
      {/* <!-- Brand Logo --> */}
      <AppName />

      {/* <!-- Sidebar --> */}
      <div className='sidebar' style={{ marginTop: 0 }}>
        {/* <!-- Sidebar user panel (optional) --> */}
        <UserPanel />

        {/* <!-- SidebarSearch Form --> */}
        <SearchForm />

        {/* <!-- Sidebar Menu --> */}
        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar flex-column'
            data-widget='treeview'
            role='menu'
            data-accordion='false'
          >
            <li className='nav-item'>
              <NavLink to='/' className='nav-link '>
                <i className='nav-icon'>
                  <FontAwesomeIcon icon={["fas", "dashboard"]} />
                </i>
                <p className='text no-select'>Dashboard</p>
              </NavLink>
            </li>

            {/* Reports */}
            {sidebarMenus.length > 0 ? (
              <li className={"nav-item " + (openMenu ? "menu-open" : "")}>
                <div
                  className='nav-link'
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <i className='nav-icon'>
                    <FontAwesomeIcon icon={faFolder} />
                  </i>
                  <p style={{ cursor: "pointer", userSelect: "none" }}>
                    Reports
                    <i className='right'>
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </i>
                  </p>
                </div>
                <ul className='nav nav-treeview'>
                  {sidebarMenus.map((menu) => {
                    return (
                      <HasAccess roles={menu?.role ?? []} key={menu.id}>
                        <li className='nav-item' key={menu.title}>
                          <NavLink to={menu.path} className='nav-link'>
                            <i className='nav-icon'>
                              <FontAwesomeIcon
                                icon={["fas", menu.icon as IconName]}
                              />
                            </i>
                            <p className='no-select'>{menu.title}</p>
                          </NavLink>
                        </li>
                      </HasAccess>
                    );
                  })}
                </ul>
              </li>
            ) : (
              <li className='nav-item'>
                <a className='nav-link '>
                  <i className='nav-icon'>
                    <FontAwesomeIcon icon={["fas", "spinner"]} spin />
                  </i>
                  <p className='text no-select'>Loading...</p>
                </a>
              </li>
            )}
            {/* End Reports */}

            <li className='nav-item'>
              <NavLink to='/tasks' className='nav-link '>
                <i className='nav-icon'>
                  <FontAwesomeIcon icon={["fas", "list-check"]} />
                </i>
                <p className='text no-select'>Tasks</p>
              </NavLink>
            </li>

            <HasAccess roles={["admin"]}>
              <li className='nav-header no-select'>ADMINISTRATOR</li>
              <li className='nav-item'>
                <NavLink to='/users' className='nav-link '>
                  <i className='nav-icon'>
                    <FontAwesomeIcon icon={["fas", "users"]} />
                  </i>
                  <p className='text no-select'>Users</p>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/reports/list' className='nav-link '>
                  <i className='nav-icon'>
                    <FontAwesomeIcon icon={["fas", "folder-tree"]} />
                  </i>
                  <p className='text no-select'>Reports</p>
                </NavLink>
              </li>
            </HasAccess>

            <li className='nav-item'>
              <NavLink to='/settings' className='nav-link '>
                <i className='nav-icon'>
                  <FontAwesomeIcon icon={["fas", "gear"]} />
                </i>
                <p className='text no-select'>Settings</p>
              </NavLink>
            </li>

            {/* <li className='nav-header no-select'>LABELS</li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='nav-icon text-danger'>
                  <FontAwesomeIcon icon={["far", "circle"]} />
                </i>
                <p className='text no-select'>Important</p>
              </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='nav-icon text-warning'>
                  <FontAwesomeIcon icon={["far", "circle"]} />
                </i>
                <p className='text no-select'>Warning</p>
              </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='nav-icon text-info'>
                  <FontAwesomeIcon icon={["far", "circle"]} />
                </i>
                <p className='text no-select'>Informational</p>
              </a>
            </li> */}
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
}
