import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useMatches } from "react-router-dom";

export function Breadcrumbs() {
  const { darkMode } = useSelector((state: any) => state.toggle);
  let matches = useMatches();
  let crumbs = matches
    .filter((match: any) => Boolean(match.handle?.crumb))
    .map((match: any) => match.handle.crumb(match.data));

  const breadcrumbs = crumbs.map((crumb, ind) => {
    const notLast = ind < crumbs.length - 1;
    if (notLast) {
      return (
        <span key={ind}>
          <span className='breadcrumb-item'>{crumb}</span>
          <span className='px-1'>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </span>
      );
    } else {
      return (
        <span key={ind} className='breadcrumb-item active'>
          {crumb}
        </span>
      );
    }
  });

  return <div className='breadcrumb float-sm-right'>{breadcrumbs}</div>;
}
