import { ROLES_URL, ROLE_URL } from "../constants/endpoints";
import axios from "../utils/AxiosInstance";

export async function GetRoles(qparams?: any) {
  return axios
    .get(ROLES_URL, { params: { ...qparams } })
    .then((response): ResponseModel<Role[]> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Role[]> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function GetRole(id: number) {
  return axios
    .get(ROLE_URL + `${id}`)
    .then((response): ResponseModel<Role> => {
      return {
        data: response.data,
        status: response.status,
        message: response.statusText,
      };
    })
    .catch(({ response }): ResponseModel<Role> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function UpdateRole(id: any, role: FormData) {
  return axios
    .put(ROLE_URL + `${id}`, role)
    .then((response): ResponseModel<Role> => {
      let role = response.data as Role;
      return {
        data: response.data,
        status: response.status,
        message: `${role.name} məlumatları yeniləndi.`,
      };
    })
    .catch(({ response }): ResponseModel<Role> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function InsertRole(role: FormData) {
  return axios
    .post(ROLE_URL, role)
    .then((response): ResponseModel<Role> => {
      let role = response.data as Role;
      return {
        data: response.data,
        status: response.status,
        message: `${role.name} əlavə edildi.`,
      };
    })
    .catch(({ response }): ResponseModel<Role> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}

export async function DeleteRole(id: number) {
  return axios
    .delete(ROLE_URL + `${id}`)
    .then((response): ResponseModel<Role> => {
      return {
        data: response.data,
        status: response.status,
        message: `${id} silindi.`,
      };
    })
    .catch(({ response }): ResponseModel<Role> => {
      return {
        data: response?.data,
        status: response?.status,
        message: response?.data?.message || response?.statusText,
      };
    });
}
