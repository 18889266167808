import { configureStore } from "@reduxjs/toolkit";
import toggle from "./slices/ToggleSlice";
import authReducer from "./slices/AuthSlice";
import dashboardReducer from "./slices/DashboardSlice";

export const store = configureStore({
  reducer: {
    toggle,
    authReducer,
    dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
