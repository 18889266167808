import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitState {
  isLoading: boolean;
  error: null | string;
  infoCards: InfoCard[];
  charts: Chart[];
  maps: DashMap[];
  allCharts: Chart[];
  allInfoCards: InfoCard[];
  allMaps: DashMap[];
  showMap: boolean;
  sidebarMenus: SidebarMenu[];
}

const initialState: InitState = {
  isLoading: false,
  error: null,
  infoCards: localStorage.getItem("infoCards")
    ? JSON.parse(localStorage.getItem("infoCards")!)
    : [],
  charts: localStorage.getItem("charts")
    ? JSON.parse(localStorage.getItem("charts")!)
    : [],
  maps: localStorage.getItem("maps")
    ? JSON.parse(localStorage.getItem("maps")!)
    : [],
  allCharts: [],
  allInfoCards: [],
  allMaps: [],
  showMap: true,
  sidebarMenus: [],
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setShowMap: (state, action) => {
      state.showMap = !state.showMap;
      localStorage.setItem("showMap", JSON.stringify(state.showMap));
    },
    //charts
    addChart: (state, action) => {
      state.charts = [...state.charts, action.payload];
      localStorage.setItem("charts", JSON.stringify(state.charts));
    },
    removeChart: (state, action) => {
      state.charts = state.charts.filter(
        (chart) => chart.id !== action.payload
      );
      localStorage.setItem("charts", JSON.stringify(state.charts));
    },
    setCharts: (state, action) => {
      state.charts = action.payload;
    },
    setAvailableCharts: (state, action) => {
      state.allCharts = action.payload;
    },
    //info card
    addInfoCards: (state, action) => {
      state.infoCards = [...state.infoCards, action.payload];
      localStorage.setItem("infoCards", JSON.stringify(state.infoCards));
    },
    removeInfoCards: (state, action) => {
      state.infoCards = state.infoCards.filter(
        (card) => card.id !== action.payload
      );
      localStorage.setItem("infoCards", JSON.stringify(state.infoCards));
    },
    setInfoCards: (state, action) => {
      state.infoCards = action.payload;
    },
    setAvailableInfoCards: (state, action) => {
      state.allInfoCards = action.payload;
    },
    //maps
    addMaps: (state, action) => {
      state.maps = [...state.maps, action.payload];
      localStorage.setItem("maps", JSON.stringify(state.maps));
    },
    removeMaps: (state, action) => {
      state.maps = state.maps.filter((map) => map.id !== action.payload);
      localStorage.setItem("map", JSON.stringify(state.maps));
    },
    setMaps: (state, action) => {
      state.maps = action.payload;
    },
    setAvailableMaps: (state, action) => {
      state.allMaps = action.payload;
    },
    //menu
    addMenu: (state, action) => {
      state.sidebarMenus = [...state.sidebarMenus, action.payload];
    },
    removeMenu: (state, action) => {
      state.sidebarMenus = state.sidebarMenus.filter(
        (m) => m.id !== action.payload
      );
    },
    setSidebarMenus: (state, action) => {
      state.sidebarMenus = action.payload;
    },
  },
});

export const {
  setShowMap,
  setCharts,
  addChart,
  removeChart,
  setInfoCards,
  setAvailableCharts,
  setAvailableInfoCards,
  addInfoCards,
  removeInfoCards,
  addMenu,
  removeMenu,
  setSidebarMenus,
  setMaps,
  addMaps,
  removeMaps,
  setAvailableMaps,
} = dashboardSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboardReducer;
export default dashboardSlice.reducer;
