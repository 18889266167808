import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type InputProps = {
  name?: string;
  id?: string;
  placeholder?: string;
  label: string;
  infotext?: string;
  icon?: IconName;
  btnClick?: (event: any) => void;
  btnicon?: IconName;
  btnname?: string;
  btncolor?:
    | "btn-outline-danger"
    | "btn-outline-success"
    | "btn-outline-primary"
    | "btn-outline-secondary"
    | "btn-outline-info"
    | "btn-outline-warning"
    | "btn-outline-light"
    | "btn-outline-dark";
  [x: string]: any;
};

export default function Input(input: InputProps) {
  const { ...rest } = input;
  const icon: IconName = input.icon ? input.icon : "circle";
  const btnIcon: IconName = input.btnicon ? input.btnicon : "check";
  return (
    <div className='form-group'>
      <label htmlFor={input.id}>
        {input.label}{" "}
        {input.infotext && <small>{"( " + input.infotext + " )"}</small>}
      </label>
      <div className='input-group'>
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            <FontAwesomeIcon icon={["fas", icon]} />
          </span>
        </div>
        <input
          name={input.name}
          className='form-control'
          id={input.id}
          placeholder={input.placeholder}
          {...rest}
        />
        {input.btnClick && (
          <div className='input-group-append'>
            <button
              className={"btn " + input.btncolor}
              onClick={input.btnClick}
            >
              <FontAwesomeIcon icon={["fas", btnIcon]} className='mr-1' />
              {input.btnname}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
